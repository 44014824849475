import React from 'react';
import ReactDOM from 'react-dom/client';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import "./assets/styles/index.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./i18n";
import "react-toastify/dist/ReactToastify.css";
import App from './pages/App';

library.add(fas);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);