export const Headers = [
    {
        id: "subject",
        col: "subject",
    },
    {
        id: "sender",
        col: "firstname",
    },
    {
        id: "senderMail",
        col: "lastname",
    }
];