import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import ModalWrapper from "./ModalWrapper";
import { Input, Textarea } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import ContactAPI from "../../services/API/clients/ContactAPI";

export default function ModalSupport({
    open,
    setOpen,
    data,
}: Readonly<{
    open: boolean;
    setOpen: () => void;
    data: {
        id: string;
        sender: {
            firstname: string;
            lastname: string;
            email: string;
        };
        subject: string;
    };
}>) {
    const { t } = useTranslation();
    const [completeData, setCompleteData] = useState<{
        id: string;
        sender: {
            firstname: string;
            lastname: string;
            email: string;
            phone?: string;
            address?: string;
        };
        subject: string;
        message: string;
    }>({
        id: "",
        sender: {
            firstname: "",
            lastname: "",
            email: "",
        },
        subject: "",
        message: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            const response = await ContactAPI.getSupport(data.id);
            setCompleteData(response.body);
        };
        fetchData();
    }, [data.id]);

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                    {t("message")}
                </Dialog.Title>
                <FontAwesomeIcon
                    icon="x"
                    onClick={() => {
                        setOpen();
                    }}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer transition-all"
                />
                <div className="flex flex-col gap-4 mt-6">
                    <div className="flex flex-row gap-4">
                        <Input crossOrigin="" color="orange" label="firstname" value={data?.sender.firstname} disabled />
                        <Input crossOrigin="" color="orange" label="lastname" value={data?.sender.lastname} disabled />
                    </div>
                    <div className="flex flex-row gap-4">
                        <Input crossOrigin="" color="orange" label="email" value={data?.sender.email} disabled />
                        {completeData?.sender?.phone && <Input crossOrigin="" color="orange" label="phone" value={completeData?.sender?.phone} disabled />}
                    </div>
                    {completeData?.sender?.address &&
                        <div className="flex flex-row gap-4">
                            <Input crossOrigin="" color="orange" label="address" value={completeData?.sender?.address} disabled />
                        </div>
                    }
                    <Input crossOrigin="" color="orange" label="subject" value={t(data?.subject ?? "")} disabled />
                    <Textarea color="orange" label="message" value={completeData?.message} disabled />
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
