import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import Header from "../../components/NavBar/Header";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import TitlePage from "../../components/TitlePage";
import { IconButton, Input, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import OrderAPI from "../../services/API/clients/OrdersAPI";
import { HeaderOrder } from "./HeaderOrder";
import TableTransactions from "../../components/Tables/TableTransactions";

export default function Orders() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("order")}`;
    const [query, setQuery] = useState<string>("");
    const [orders, setOrders] = useState<{
        pagination: { current: number; last: number; totalItems: number; parPage: number };
        data: {
            id: "string";
            totalPrice: 0;
            orderId: "string";
            status: "PENDING";
            createdAt: "CURRENT_TIMESTAMP";
            contracts: [
                {
                    id: "string";
                    picture: {
                        id: "string";
                        minFilePath: "string";
                        width: 0;
                        height: 0;
                    };
                }
            ];
        }[];
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0,
        },
        data: [],
    });

    const getList = async () => {
        const response = await OrderAPI.orders();
        if (response.status === 200) {
            setOrders(response.body);
        }
    };

    useEffect(() => {
        getList();
    }, []);

    const callPictures = async (page: number) => {
        const response = await OrderAPI.ordersPagination(page, query);
        if (response.status === 200) {
            setOrders(response.body);
        }
    };

    const handleResearchWithQuery = async () => {
        const response = await OrderAPI.orders(query);
        if (response.status === 200) {
            setOrders(response.body);
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage>
                        <FontAwesomeIcon icon="shopping-cart" className="text-orange-500" /> {t("order")}
                    </TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                    <div className="w-full max-w-7xl mx-auto md:px-8 flex flex-col gap-4">
                        {orders?.pagination.totalItems > 0 ? (
                            <div className="w-full max-w-7xl mx-auto flex flex-col gap-4">
                                <div className="flex flex-row items-center justify-between w-full gap-4">
                                    <Input
                                        crossOrigin=""
                                        type="text"
                                        color="orange"
                                        className="bg-white"
                                        label={t("search")}
                                        value={query}
                                        onChange={(e) => setQuery(e.target.value)}
                                    />
                                    <IconButton placeholder="" color="orange" onClick={() => handleResearchWithQuery()}>
                                        <FontAwesomeIcon icon="paper-plane" />
                                    </IconButton>
                                </div>
                                <TableTransactions header={HeaderOrder} data={orders?.data} itemsPerPage={orders?.pagination} callPictures={callPictures} />
                            </div>
                        ) : (
                            <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                                <FontAwesomeIcon icon="images" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                                <Typography placeholder="" className="text-base text-gray-700 mb-4">
                                    {t("noSubs")}
                                </Typography>
                            </div>
                        )}
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
