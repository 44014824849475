import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import ModalWrapper from "./ModalWrapper";
import { useState } from "react";
import { Checkbox, Option, Select, Textarea, Typography } from "@material-tailwind/react";
import { toast } from "react-toastify";
import PicturesAPI from "../../services/API/clients/PicturesAPI";

const datasCheckbox = [
    {
        id: 1,
        label: "edit",
    },
    {
        id: 2,
        label: "upload_documents",
    },
    {
        id: 3,
        label: "upload_original",
    }
]

export default function ModalRefusePicture({ open, setOpen, currentId, isInEdit = false }: Readonly<{ open: boolean; setOpen: () => void, currentId: string | string[], isInEdit?: boolean }>) {
    const { t } = useTranslation();
    const [isDelete, setIsDelete] = useState<string>("false");
    const [reasons, setReasons] = useState<string[]>([]);
    const [message, setMessage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = () => {
        if (reasons.length === 0 && isDelete === "false") {
            toast.error(t("selectReason"));
            return;
        }
        handleRefuse(isDelete, reasons, message);
        setOpen();
    };

    const handleRefuse = async (isDelete: string, motifReasons: string[], message: string) => {
        if (loading) return;
        let datas : { action: string, requiredActions?: string[], message?: string };
        if (isDelete === "true") datas = { action: "delete" };
        else
            datas = {
                action: "reject",
                requiredActions: motifReasons,
                message: message,
            };
        setLoading(true);
        if (Array.isArray(currentId)) {
            Promise.all(currentId.map(async (check: string) => {
                await PicturesAPI.validatePicture(check, datas);
            }))
        } else {
            let response
            if (isInEdit) {
                response = await PicturesAPI.putPicture(currentId, datas);
            } else {
                response = await PicturesAPI.validatePicture(currentId, datas);
            }
            if (response.status === 200) {
                setLoading(false);
            } else {
                setLoading(false);
                toast.error("Error");
            }
        }
    };

    const handleDelete = () => {
        if (isDelete === "true") {
            setIsDelete("false");
        }
    }

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center mb-4">
                    {t("picturerefuse")}
                </Dialog.Title>
                <FontAwesomeIcon
                    icon="x"
                    onClick={() => {
                        setOpen();
                    }}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer transition-all"
                />

                <div className="w-[400px] relative flex flex-col gap-3">
                    <Select
                        label={t("typeRefuse")}
                        placeholder=""
                        value={isDelete}
                        onChange={(e: string | undefined) => e && setIsDelete(e)}
                        color="orange"
                        className="bg-white"
                    >
                        <Option value="false">{t("refuse")}</Option>
                        <Option value="true">{t("delete")}</Option>
                    </Select>
                    {isDelete === "false" && (
                        <div className="flex flex-col">
                            <Typography placeholder="" className="text-sm text-gray-600">
                                {t("actionByUser")}
                            </Typography>
                            {datasCheckbox.map((data) => (
                                <Checkbox
                                    key={data.id}
                                    crossOrigin=""
                                    label={t(data.label)}
                                    checked={reasons.includes(data.label)}
                                    color="orange"
                                    className="h-4 w-4"
                                    labelProps={{ className: "text-sm" }}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setReasons([...reasons, data.label]);
                                        } else {
                                            setReasons(reasons.filter((reason) => reason !== data.label));
                                        }
                                    }}
                                />
                            ))}
                        </div>
                    )}
                    <Textarea
                        label={t("comment")}
                        placeholder=""
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        color="orange"
                        className="bg-white"
                    />
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    <button
                        type="button"
                        className="rounded-full text-white bg-orange-500 hover:bg-orange-200 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => handleSubmit()}
                    >
                        {t("confirm")}
                    </button>
                    <button
                        type="button"
                        className="rounded-full text-black border bg-white hover:bg-gray-50 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("cancel")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
