import { useEffect, useState } from "react";
import ModalAddGestion from "../../../components/Modals/ModalAddGestion";
import { useTranslation } from "react-i18next";
import { Button, Input } from "@material-tailwind/react";
import WrapperCards from "../../../components/Cards/WrapperCards";
import StylesAPI from "../../../services/API/clients/StylesAPI";
import { toast } from "react-toastify";

export default function Styles() {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState<string>("");
    const [data, setData] = useState<{ enumValue: string; frLabel: string; enLabel: string }[]>([]);
    const [dataFiltered, setDataFiltered] = useState<{ enumValue: string; frLabel: string; enLabel: string }[]>([]);
    const [dataEdit, setDataEdit] = useState<{ enumValue: string; frLabel: string; enLabel: string }>({ enumValue: "", frLabel: "", enLabel: "" });
    const [currentEdit, setCurrentEdit] = useState<number>(-1);

    const fetchData = async () => {
        const response = await StylesAPI.getAll();
        if (response.status === 200) {
            setData(response.body);
            setDataFiltered(response.body);
        }
    };

    const onDelete = async (enumValue: string) => {
        const response = await StylesAPI.deleteStyles(enumValue);
        if (response.status === 204) {
            fetchData();
            setCurrentEdit(-1);
            setDataEdit({ enumValue: "", frLabel: "", enLabel: "" });
            toast.success(t("styleDeleted"));
        } else if (response.status === 409) {
            toast.warning(t("styleUsed"));
        } else {
            toast.warning(t("styleUsed"));
            // toast.error(t("error"));
        }
    };

    const onSave = async () => {
        const datas = {
            enumValue: dataEdit.enumValue,
            frLabel: dataEdit.frLabel,
            enLabel: dataEdit.enLabel,
        };

        const response = await StylesAPI.updateStyles(dataEdit.enumValue, datas);
        if (response.status === 200) {
            fetchData();
            setCurrentEdit(-1);
            setDataEdit({ enumValue: "", frLabel: "", enLabel: "" });
            toast.success(t("styleUpdated"));
        } else {
            toast.error(t("error"));
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <ModalAddGestion open={open} setOpen={() => setOpen(false)} fetchData={() => {}} type="styles" />
            <Button placeholder="" onClick={() => setOpen(true)} className="mb-4">
                {t("stylesAdd")}
            </Button>

            <Input
                crossOrigin=""
                type="text"
                color="orange"
                label={t("search")}
                value={query}
                onChange={(e) => {
                    setQuery(e.target.value);
                    setDataFiltered(data.filter((cat) => cat.enumValue.toLowerCase().includes(e.target.value.toLowerCase())));
                }}
            />

            <WrapperCards
                dataFiltered={dataFiltered}
                currentEdit={currentEdit}
                setCurrentEdit={setCurrentEdit}
                dataEdit={dataEdit}
                setDataEdit={setDataEdit}
                onSave={onSave}
                onDelete={onDelete}
            />
        </>
    );
}
