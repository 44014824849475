import APIClient from "../APIClients";

export default class Auth {
    public static async signin(data: { username : string, password: string }) {
        return APIClient.noInterceptor.post("/signin", data);
    }

    public static async signup(data: { email: string, firstname: string, lastname: string, plainPassword: string }) {
        return APIClient.noInterceptor.post("/signup", data);
    }

    public static async signinGoogle(data: { oAuthCode: string }) {
        return APIClient.noInterceptor.post(`/google-signin`, data);
    }

    public static async connect2fa(data: {}) {
        return APIClient.noInterceptor.post(`/signin-totp`, data);
    }
}