import APIClient from "../APIClients";

export default class StylesAPI {
    public static async getAll() {
        return APIClient.ezoom.get(`/public/photo-styles`);
    }

    public static async newStyles(data: {}) {
        return APIClient.ezoom.post(`/photo-styles`, data);
    }

    public static async deleteStyles(id: string) {
        return APIClient.ezoom.delete(`/photo-styles/${id}`);
    }

    public static async updateStyles(id: string, data: {}) {
        return APIClient.ezoom.put(`/photo-styles/${id}`, data);
    }
}