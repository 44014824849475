import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Button, Checkbox, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { WithdrawData, WithdrawProps } from "../../components/Interface/Withdraw";
import TableWithdrawHistory from "../../components/Tables/TableWithdrawHistory";
import { useEffect, useState } from "react";
import moment from "moment";
import WithdrawAPI from "../../services/API/clients/WithdrawAPI";
import Pagination from "../../components/Tables/Pagination";
import { HeadersWithdrawHistory } from "./Headers";

export default function WithdrawHistory({
    fetchDataHistory,
    callPictures,
    datasHistory,
}: Readonly<{ fetchDataHistory: () => void; callPictures: (arg: number) => void; datasHistory: WithdrawProps }>) {
    const { t } = useTranslation();
    const [selectedId, setSelectedId] = useState<string[]>([]);

    useEffect(() => {
        fetchDataHistory();
    }, []);

    const returnTypo = (col: string, el: WithdrawData) => {
        switch (col) {
            case "id":
                return el.id;
            case "createdAt":
                return moment(el.createdAt).format("DD.MM.YYYY HH:mm");
            case "withdrawDate":
                return moment(el.withdrawDate).format("DD.MM.YYYY HH:mm");
            case "saleId":
                return el.saleId;
            case "netWorth":
                return el.netWorth;
            case "orderStatus":
                return el.orderStatus;
            case "contracts":
                return (
                    <div className="flex items-center -space-x-4">
                        {el.contracts.map((contract) => (
                            <Avatar
                                placeholder=""
                                key={contract.id}
                                variant="circular"
                                alt="user 1"
                                className="border-2 border-white"
                                src={contract.picture.minPath}
                            />
                        ))}
                    </div>
                );
            default:
                return "";
        }
    };

    const markAsPaid = async () => {
        const data = {
            withdrawDate: null,
        };
        await Promise.all(selectedId.map((id) => WithdrawAPI.unWithdraw(id, data)));
        fetchDataHistory();
        setSelectedId([]);
    };

    return (
        <>
            {datasHistory?.pagination.totalItems > 0 ? (
                <div className="w-full max-w-7xl mx-auto flex flex-col gap-4">
                    <div className="flex gap-4">
                        <Button
                            placeholder=""
                            color="purple"
                            onClick={() => {
                                markAsPaid();
                            }}
                        >
                            {t("markAsUnPaid")}
                        </Button>
                    </div>
                    <div className="table-container">
                        <div className="w-full overflow-x-auto max-h-[52vh]">
                            <table className="w-full table-auto overflow-x-scroll">
                                <thead>
                                    <tr>
                                        <th className="border-b border-blue-gray-50 py-3 px-5 text-center"></th>
                                        {HeadersWithdrawHistory?.map((column: { col: string; id: string }, index: number) => (
                                            <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                                <Typography
                                                    placeholder=""
                                                    variant="small"
                                                    className={`text-[11px] font-bold uppercase text-blue-gray-400 whitespace-nowrap ${
                                                        index === HeadersWithdrawHistory.length - 1 ? "text-center" : "text-left"
                                                    }`}
                                                >
                                                    {t(column.id)}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {datasHistory.data.map((el, key: number) => {
                                        const className = `py-1 px-5 w-fit ${key === datasHistory.data.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                                        return (
                                            <tr key={el.id} className={`example-element-row`}>
                                                <td className={`py-5 border-b flex flex-row`} style={{ textAlign: "center" }}>
                                                    <Checkbox
                                                        crossOrigin=""
                                                        color="orange"
                                                        checked={selectedId.includes(el.id)}
                                                        onChange={() => {
                                                            if (selectedId.includes(el.id)) {
                                                                setSelectedId(selectedId.filter((id) => id !== el.id));
                                                            } else {
                                                                setSelectedId([...selectedId, el.id]);
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                {HeadersWithdrawHistory?.map((column: { id: string; col: string }) => {
                                                    return (
                                                        <td key={column.id} className={className}>
                                                            <Typography
                                                                placeholder=""
                                                                variant="small"
                                                                className={`text-[11px] text-blue-gray-400 whitespace-nowrap ${
                                                                    key === HeadersWithdrawHistory.length - 1 ? "text-center" : "text-left"
                                                                }`}
                                                            >
                                                                {returnTypo(column.col, el)}
                                                            </Typography>
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            handlePageChange={callPictures}
                            itemsPerPage={datasHistory.pagination.parPage}
                            currentPage={datasHistory.pagination.current}
                            totalItems={datasHistory.pagination.totalItems}
                            startIndex={datasHistory.pagination.current * datasHistory.pagination.parPage - datasHistory.pagination.parPage}
                            endIndex={
                                datasHistory.pagination.current * datasHistory.pagination.parPage - 1 > datasHistory.pagination.totalItems
                                    ? datasHistory.pagination.totalItems - 1
                                    : datasHistory.pagination.current * datasHistory.pagination.parPage - 1
                            }
                        />
                    </div>
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                    <FontAwesomeIcon icon="dollar" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                    <Typography placeholder="" className="text-base text-gray-700 mb-4">
                        {t("noPaiement")}
                    </Typography>
                </div>
            )}
        </>
    );
}
