import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, IconButton, Input, Typography } from "@material-tailwind/react";
import AppSettingsAPI from "../../../services/API/clients/AppSettingsAPI";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function Excluded({
    excludeSearchWords,
    setExcludeSearchWords,
    fetchData,
}: Readonly<{ excludeSearchWords: string[]; setExcludeSearchWords: Function; fetchData: Function }>) {
    const { t } = useTranslation();

    const handleSubmit = async (key: string) => {
        const datas = {
            value: excludeSearchWords.filter((word) => word !== ""),
        };
        const response = await AppSettingsAPI.modifAppSetting(key, datas);
        if (response.status === 200 || response.status === 201) {
            toast.success(t("save_success"));
            fetchData();
        }
    };

    return (
        <>
            <Typography placeholder="" variant="h5">
                {t("EXCLUDE_SEARCH_WORDS")}
            </Typography>
            <Typography placeholder="" variant="small">
                {t("EXCLUDE_SEARCH_WORDS_d")}
            </Typography>
            <Card placeholder="" className="flex flex-col gap-2 mt-4 w-fit p-4">
                <>
                    {excludeSearchWords.map((word, index) => (
                        <div key={index} className="flex flex-row items-center gap-2">
                            <IconButton
                                placeholder=""
                                color="red"
                                size="sm"
                                variant="outlined"
                                onClick={() => {
                                    const newWords = [...excludeSearchWords];
                                    newWords.splice(index, 1);
                                    setExcludeSearchWords(newWords);
                                }}
                            >
                                <FontAwesomeIcon icon="trash" />
                            </IconButton>
                            <Typography placeholder="" variant="h6">
                                <Input
                                    crossOrigin=""
                                    variant="static"
                                    id={`excludeSearchWords-${index}`}
                                    type="text"
                                    value={word}
                                    onChange={(e) => {
                                        const newWords = [...excludeSearchWords];
                                        newWords[index] = e.target.value;
                                        setExcludeSearchWords(newWords);
                                    }}
                                />
                            </Typography>
                        </div>
                    ))}
                    <IconButton
                        placeholder=""
                        color="green"
                        size="sm"
                        variant="outlined"
                        onClick={() => {
                            setExcludeSearchWords([...excludeSearchWords, ""]);
                        }}
                    >
                        <FontAwesomeIcon icon="plus" />
                    </IconButton>
                </>
            </Card>
            <div className="w-fit mt-4">
                <Button placeholder="" onClick={() => handleSubmit("EXCLUDE_SEARCH_WORDS")}>
                    {t("save")}
                </Button>
            </div>
        </>
    );
}
