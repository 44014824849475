import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, IconButton, Input, Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/NavBar/Header";
import TableUsers from "../../components/Tables/TableUsers";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import UsersAPI from "../../services/API/clients/UsersAPI";
import { Headers } from "./Header";

export default function Users() {
    const { t } = useTranslation();
    const [list, setList] = React.useState<any[]>([]);
    const [listValidated, setListValidated] = React.useState<any[]>([]);
    const [query, setQuery] = React.useState<string>("");
    const [pagination, setPagination] = React.useState<{ current: number, last: number, totalItems: number, parPage: number}>({
        current: 0,
        last: 0,
        totalItems: 0,
        parPage: 0
    });
    const [paginationValidated, setPaginationValidated] = React.useState<{ current: number, last: number, totalItems: number, parPage: number}>({
        current: 0,
        last: 0,
        totalItems: 0,
        parPage: 0
    });
    document.title = `ezoom | ${t("users")}`;

    const getList = async () => {
        const response = await UsersAPI.users();
        setList(response.body.data);
        setPagination({
            current: response.body.pagination.current,
            last: response.body.pagination.last,
            totalItems: response.body.pagination.totalItems,
            parPage: response.body.pagination.parPage
        })
    }

    const getListValidated = async () => {
        const response = await UsersAPI.usersValidated();
        setListValidated(response.body.data);
        setPaginationValidated({
            current: response.body.pagination.current,
            last: response.body.pagination.last,
            totalItems: response.body.pagination.totalItems,
            parPage: response.body.pagination.parPage
        })
    }

    useEffect(() => {
        getList()
        getListValidated()
    }, [])

    const callUsers = async (page: number) => {
        const response = await UsersAPI.usersPagination(page, query);
        setList(response.body.data);
        setPagination({
            current: response.body.pagination.current,
            last: response.body.pagination.last,
            totalItems: response.body.pagination.totalItems,
            parPage: response.body.pagination.parPage
        })
    }

    const callUsersValidated = async (page: number) => {
        const response = await UsersAPI.usersPaginationValidated(page, query);
        setListValidated(response.body.data);
        setPaginationValidated({
            current: response.body.pagination.current,
            last: response.body.pagination.last,
            totalItems: response.body.pagination.totalItems,
            parPage: response.body.pagination.parPage
        })
    }

    const handleResearchWithQuery = async () => {
        const response = await UsersAPI.users(query);
        setList(response.body.data);
        setPagination({
            current: response.body.pagination.current,
            last: response.body.pagination.last,
            totalItems: response.body.pagination.totalItems,
            parPage: response.body.pagination.parPage
        })
        const responseValidated = await UsersAPI.usersValidated(query);
        setListValidated(responseValidated.body.data);
        setPaginationValidated({
            current: responseValidated.body.pagination.current,
            last: responseValidated.body.pagination.last,
            totalItems: responseValidated.body.pagination.totalItems,
            parPage: responseValidated.body.pagination.parPage
        })
    }

    return (
        <div className="min-h-screen bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage><FontAwesomeIcon icon="users" className="text-orange-500" /> {t("users")}</TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                    <div className="w-full max-w-7xl mx-auto md:px-8 flex flex-col gap-4">
                        <div className="flex flex-row items-center justify-between w-full gap-4">
                            <Input crossOrigin="" type="text" color="orange" className="bg-white" label={t("search")} value={query} onChange={(e) => setQuery(e.target.value)} />
                            <IconButton placeholder="" color="orange" onClick={() => handleResearchWithQuery()}>
                                <FontAwesomeIcon icon="paper-plane" />
                            </IconButton>
                        </div>
                        <Tabs value="all">
                            <TabsHeader placeholder="">
                                <Tab placeholder="" value="all">
                                    {t("all")}
                                </Tab>
                                <Tab placeholder="" value="validated">
                                    {t("canBeValidated")}
                                </Tab>
                            </TabsHeader>
                            <TabsBody placeholder="">
                                <TabPanel value="all" className="mt-2">
                                    {list.length > 0 ? (
                                        <TableUsers header={Headers} data={list} itemsPerPage={pagination} callUsers={callUsers} />
                                    ) : (
                                        <Alert color="blue-gray">
                                            {t("noUsers")}
                                        </Alert>
                                    )}
                                </TabPanel>
                                <TabPanel value="validated" className="mt-2">
                                    {listValidated.length > 0 ? (
                                        <TableUsers header={Headers} data={listValidated} itemsPerPage={paginationValidated} callUsers={callUsersValidated} />
                                    ) : (
                                        <Alert color="blue-gray">
                                            {t("noUsers")}
                                        </Alert>
                                    )}
                                </TabPanel>
                            </TabsBody>
                        </Tabs>
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
