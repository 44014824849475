import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchAppSettings } from "../../components/Interface/AppSettings";
import AppSettingsAPI from "../../services/API/clients/AppSettingsAPI";
import Excluded from "./Setter/Excluded";
import Restrictions from "./Setter/Restrictions";
import Recents from "./Setter/Recents";
import HomePicture from "./Setter/HomePicture";
import Populars from "./Setter/Populars";

export default function Setter() {
    const { t } = useTranslation();
    const [excludeSearchWords, setExcludeSearchWords] = useState<string[]>([]);
    const [homePicture, setHomePicture] = useState<string>("");
    const [popularBonusPaid, setPopularBonusPaid] = useState<{ day: number | null; score: number }[]>([]);
    const [popularBonusStandard, setPopularBonusStandard] = useState<{ day: number | null; score: number }[]>([]);
    const [popularMalusPaid, setPopularMalusPaid] = useState<{ day: number | null; score: number }[]>([]);
    const [popularMalusStandard, setPopularMalusStandard] = useState<{ day: number | null; score: number }[]>([]);
    const [popularMinFavorites, setPopularMinFavorites] = useState<number>(0);
    const [popularMinViews, setPopularMinViews] = useState<number>(0);
    const [recentGroupDays, setRecentGroupDays] = useState<number>(0);
    const [restrictKeywords, setRestrictKeywords] = useState<{ categories: string[]; typeStyles: string[] }>({ categories: [], typeStyles: [] });

    const fetchData = async () => {
        const response = await AppSettingsAPI.getAll();
        if (response.status === 200) {
            const data: SearchAppSettings = response.body;
            setExcludeSearchWords(data.data.find((item) => item.key === "EXCLUDE_SEARCH_WORDS")?.value as string[]);
            setHomePicture(data.data.find((item) => item.key === "HOME_PICTURE")?.value as string);
            setPopularBonusPaid(data.data.find((item) => item.key === "POPULAR_BONUS_PAID")?.value as { day: number | null; score: number }[]);
            setPopularBonusStandard(data.data.find((item) => item.key === "POPULAR_BONUS_STANDARD")?.value as { day: number | null; score: number }[]);
            setPopularMalusPaid(data.data.find((item) => item.key === "POPULAR_MALUS_PAID")?.value as { day: number | null; score: number }[]);
            setPopularMalusStandard(data.data.find((item) => item.key === "POPULAR_MALUS_STANDARD")?.value as { day: number | null; score: number }[]);
            setPopularMinFavorites(data.data.find((item) => item.key === "POPULAR_MIN_FAVORITES")?.value as number);
            setPopularMinViews(data.data.find((item) => item.key === "POPULAR_MIN_VIEWS")?.value as number);
            setRecentGroupDays(data.data.find((item) => item.key === "RECENT_GROUP_DAYS")?.value as number);
            setRestrictKeywords(data.data.find((item) => item.key === "RESTRICT_KEYWORDS")?.value as { categories: string[]; typeStyles: string[] });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Tabs value="excluded">
            <TabsHeader placeholder="">
                <Tab placeholder="" value="excluded">
                    {t("keywords")}
                </Tab>
                <Tab placeholder="" value="popular">
                    {t("popular")}
                </Tab>
                <Tab placeholder="" value="homePicture">
                    {t("homePicture")}
                </Tab>
                <Tab placeholder="" value="recents">
                    {t("recents")}
                </Tab>
                <Tab placeholder="" value="restrictions">
                    {t("restrictions")}
                </Tab>
            </TabsHeader>
            <TabsBody placeholder="">
                <TabPanel value="excluded">
                    <Excluded excludeSearchWords={excludeSearchWords} setExcludeSearchWords={setExcludeSearchWords} fetchData={fetchData} />
                </TabPanel>
                <TabPanel value="popular">
                    <Populars
                        popularBonusPaid={popularBonusPaid}
                        setPopularBonusPaid={setPopularBonusPaid}
                        popularBonusStandard={popularBonusStandard}
                        setPopularBonusStandard={setPopularBonusStandard}
                        popularMalusPaid={popularMalusPaid}
                        setPopularMalusPaid={setPopularMalusPaid}
                        popularMalusStandard={popularMalusStandard}
                        setPopularMalusStandard={setPopularMalusStandard}
                        popularMinFavorites={popularMinFavorites}
                        setPopularMinFavorites={setPopularMinFavorites}
                        popularMinViews={popularMinViews}
                        setPopularMinViews={setPopularMinViews}
                        fetchData={fetchData}
                    />
                </TabPanel>
                <TabPanel value="homePicture">
                    <HomePicture homePicture={homePicture} setHomePicture={setHomePicture} fetchData={fetchData} />
                </TabPanel>
                <TabPanel value="recents">
                    <Recents recentGroupDays={recentGroupDays} setRecentGroupDays={setRecentGroupDays} fetchData={fetchData} />
                </TabPanel>
                <TabPanel value="restrictions">
                    <Restrictions restrictKeywords={restrictKeywords} setRestrictKeywords={setRestrictKeywords} fetchData={fetchData} />
                </TabPanel>
            </TabsBody>
        </Tabs>
    );
}
