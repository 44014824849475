import APIClient from "../APIClients";

export default class AppSettingsAPI {
    public static async getAll(page?: number) {
        return APIClient.ezoom.get(`/app-settings${page ? `?page=${page}` : ""}`);
    }

    public static async modifAppSetting(key: string, data: {}) {
        return APIClient.ezoom.put(`/app-settings/${key}`, data);
    }
}