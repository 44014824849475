import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import Cats from "./Gestion/Cats";
import Services from "./Gestion/Services";
import Types from "./Gestion/Types";
import Styles from "./Gestion/Styles";
import Keywords from "./Gestion/Keywords";

export default function Gestion() {
    const { t } = useTranslation();

    return (
        <Tabs value="cats">
            <TabsHeader placeholder="">
                <Tab placeholder="" value="cats">
                    {t("cats")}
                </Tab>
                <Tab placeholder="" value="services">
                    {t("services")}
                </Tab>
                <Tab placeholder="" value="types">
                    {t("types")}
                </Tab>
                <Tab placeholder="" value="styles">
                    {t("styles")}
                </Tab>
                <Tab placeholder="" value="keywords">
                    {t("keywords")}
                </Tab>
            </TabsHeader>
            <TabsBody placeholder="">
                <TabPanel value="cats">
                    <Cats />
                </TabPanel>
                <TabPanel value="services">
                    <Services />
                </TabPanel>
                <TabPanel value="types">
                    <Types />
                </TabPanel>
                <TabPanel value="styles">
                    <Styles />
                </TabPanel>
                <TabPanel value="keywords">
                    <Keywords />
                </TabPanel>
            </TabsBody>
        </Tabs>
    );
}
