export const HeaderDetails = [
    {
        id: "id",
        col: "id"
    },
    {
        id: "orderId",
        col: "orderId"
    },
    {
        id: "status",
        col: "status"
    },
    {
        id: "createdAt",
        col: "createdAt"
    },
    {
        id: "buyQuittance",
        col: "buyQuittance"
    },
    {
        id: "sellQuittance",
        col: "sellQuittance"
    },
]