import APIClient from "../APIClients";

export default class ContactAPI {
    public static async support() {
        return APIClient.ezoom.get(`/contact-supports`);
    }

    public static async getSupport(id: string) {
        return APIClient.ezoom.get(`/contact-supports${id !== "" ? `/${id}`: ""}`);
    }
}