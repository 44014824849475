import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { Checkbox, Input, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ModalWrapper from "./ModalWrapper";
import { toast } from "react-toastify";
import VouchersAPI from "../../services/API/clients/VouchersAPI";

export default function ModalVouchers({
    open,
    setOpen,
}: Readonly<{
    open: boolean;
    setOpen: () => void;
}>) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [vouch, setVouch] = useState<{
        code: string;
        maxUsage: string;
        discountPct: string;
        beginDate: string;
        endDate: string;
        usages: { monthly: boolean; yearly: boolean };
        plans: { premium: boolean; pro: boolean };
    }>({
        code: "",
        maxUsage: "",
        discountPct: "",
        beginDate: "",
        endDate: "",
        usages: {
            monthly: false,
            yearly: false,
        },
        plans: {
            premium: false,
            pro: false,
        },
    });

    const handleSubmit = async () => {
        if (loading) return;
        if (vouch.code === "") {
            toast.warning(t("codeRequired"));
        } else if (!Number(vouch.maxUsage)) {
            toast.warning(t("maxUsageRequired"));
        } else if ((!Number(vouch.discountPct) && Number(vouch.discountPct) > 100) || Number(vouch.discountPct) < 0) {
            toast.warning(t("discountPctRequired"));
        } else if ((vouch.beginDate === "" && vouch.endDate !== "") || (vouch.beginDate !== "" && vouch.endDate === "")) {
            toast.warning(t("bothDatesRequired"));
        } else if (!vouch.usages.monthly && !vouch.usages.yearly) {
            toast.warning(t("usagesRequired"));
        } else if (!vouch.plans.premium && !vouch.plans.pro) {
            toast.warning(t("plansRequired"));
        } else {
            setLoading(true);
            const datas = {
                code: vouch.code,
                maxUsage: Number(vouch.maxUsage),
                discountPct: Number(vouch.discountPct),
                ...(vouch.beginDate !== "" && { beginDate: vouch.beginDate }),
                ...(vouch.endDate !== "" && { endDate: vouch.endDate }),
                ...((!vouch.usages.monthly || !vouch.usages.yearly) && { period: vouch.usages.monthly ? "MONTHLY" : "YEARLY" }),
                ...((!vouch.plans.premium || !vouch.plans.pro) && { planType: vouch.plans.premium ? "PREMIUM" : "PRO" }),
            };
            const response = await VouchersAPI.newVouchers(datas);
            if (response.status === 201) {
                toast.success(t("voucherCreated"));
                setLoading(false);
                setVouch({ code: "", maxUsage: "", discountPct: "", beginDate: "", endDate: "", usages: { monthly: false, yearly: false }, plans: { premium: false, pro: false }});
                setOpen();
            } else {
                toast.error(t("error"));
                setLoading(false);
            }
        }
    };

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl max-h-screen overflow-y-auto">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                    {t("newVoucher")}
                </Dialog.Title>
                <FontAwesomeIcon
                    icon="x"
                    onClick={() => {
                        setOpen();
                    }}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer transition-all"
                />

                <div className="flex flex-col gap-3 mt-6">
                    <Input
                        crossOrigin=""
                        color="orange"
                        type="text"
                        onChange={(e) => setVouch({ ...vouch, code: e.target.value })}
                        value={vouch.code}
                        label={t("code")}
                    />
                    <Input
                        crossOrigin=""
                        color="orange"
                        type="text"
                        onChange={(e) => setVouch({ ...vouch, maxUsage: e.target.value })}
                        value={vouch.maxUsage}
                        label={t("maxUsage")}
                    />
                    <Input
                        crossOrigin=""
                        color="orange"
                        type="string"
                        onChange={(e) => setVouch({ ...vouch, discountPct: e.target.value })}
                        value={vouch.discountPct}
                        label={t("discountPct")}
                    />
                    <Input
                        crossOrigin=""
                        color="orange"
                        type="date"
                        onChange={(e) => setVouch({ ...vouch, beginDate: e.target.value })}
                        value={vouch.beginDate}
                        label={t("beginDate")}
                    />
                    <Input
                        crossOrigin=""
                        color="orange"
                        type="date"
                        onChange={(e) => setVouch({ ...vouch, endDate: e.target.value })}
                        value={vouch.endDate}
                        label={t("endDate")}
                    />
                    <Typography placeholder="" color="gray">
                        {t("usagesPossible")}
                    </Typography>
                    <Checkbox
                        crossOrigin=""
                        color="orange"
                        label={t("MONTHLY")}
                        checked={vouch.usages.monthly}
                        onChange={(e) => setVouch({ ...vouch, usages: { ...vouch.usages, monthly: e.target.checked } })}
                    />
                    <Checkbox
                        crossOrigin=""
                        color="orange"
                        label={t("YEARLY")}
                        checked={vouch.usages.yearly}
                        onChange={(e) => setVouch({ ...vouch, usages: { ...vouch.usages, yearly: e.target.checked } })}
                    />
                    <Typography placeholder="" color="gray">
                        {t("plansPossible")}
                    </Typography>
                    <Checkbox
                        crossOrigin=""
                        color="orange"
                        label={t("PREMIUM")}
                        checked={vouch.plans.premium}
                        onChange={(e) => setVouch({ ...vouch, plans: { ...vouch.plans, premium: e.target.checked } })}
                    />
                    <Checkbox
                        crossOrigin=""
                        color="orange"
                        label={t("PRO")}
                        checked={vouch.plans.pro}
                        onChange={(e) => setVouch({ ...vouch, plans: { ...vouch.plans, pro: e.target.checked } })}
                    />
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    <button
                        type="button"
                        className="rounded-full text-white bg-orange-500 hover:bg-orange-200 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => handleSubmit()}
                    >
                        {loading ? <FontAwesomeIcon icon="spinner" spin /> : t("create")}
                    </button>
                    <button
                        type="button"
                        className="rounded-full text-black border bg-white hover:bg-gray-50 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("cancel")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
