import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "@material-tailwind/react";

export default function ProfilDropDown() {
    const { t } = useTranslation();
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className="inline-flex justify-center rounded-md text-sm text-gray-900 items-center ml-2"
                    style={{ width: "40px", height: "40px" }}
                >
                    <IconButton placeholder="" color="orange" className="px-2 mr-1 h-8 -mt-2 w-16">
                        <FontAwesomeIcon icon="bars" className="text-white" />
                    </IconButton>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={`absolute right-0 z-50 flex-col px-4 py-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-comfortaa font-thin`}
                >
                    <div className="py-1">
                        <Menu.Item>
                            <a href="/newsletter">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <FontAwesomeIcon icon="envelope" className="mr-2" />
                                    {t("newsletter")}
                                </button>
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href="/users">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <FontAwesomeIcon icon="users" className="mr-2" />
                                    {t("users")}
                                </button>
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href="/pictures">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <FontAwesomeIcon icon="images" className="mr-2" />
                                    {t("pictures")}
                                </button>
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href="/subscriptions">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <FontAwesomeIcon icon="user-plus" className="mr-2" />
                                    {t("subscriptions")}
                                </button>
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href="/orders">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <FontAwesomeIcon icon="shopping-cart" className="mr-2" />
                                    {t("order")}
                                </button>
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href="/vouchers">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <FontAwesomeIcon icon="ticket" className="mr-2" />
                                    {t("vouchers")}
                                </button>
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href="/paiements">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <FontAwesomeIcon icon="credit-card" className="mr-2" />
                                    {t("paiements")}
                                </button>
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href="/settings">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <FontAwesomeIcon icon="gears" className="mr-2" />
                                    {t("settings")}
                                </button>
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a href="/communications">
                                <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg">
                                    <FontAwesomeIcon icon="envelope-open-text" className="mr-2" />
                                    {t("communications")}
                                </button>
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <button className="my-2 hover:bg-gradient-to-r hover:from-orange-500 hover:to-orange-100 hover:text-white text-gray-500 p-2 text-sm w-full text-left rounded-lg"
                                onClick={() => {
                                    localStorage.removeItem("token");
                                    window.location.href = "/";
                                }}
                            >
                                <FontAwesomeIcon icon="right-from-bracket" className="mr-2" />
                                {t("disconnect")}
                            </button>
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
