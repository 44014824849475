export const HeaderDetails = [
    {
        id: "id",
        col: "id"
    },
    {
        id: "status",
        col: "status"
    },
    {
        id: "internalId",
        col: "internalId"
    },
    {
        id: "paymentMethodName",
        col: "paymentMethodName"
    },
    {
        id: "displayText",
        col: "displayText"
    },
    {
        id: "amount",
        col: "amount"
    },
    {
        id: "createdAt",
        col: "createdAt"
    },
]