import APIClient from "../APIClients";

export default class SubscriptionsAPI {
    public static async validationPictures(query: string) {
        return APIClient.ezoom.get(`/picture-validations?${query}`);
    }

    public static async validationPicturesPaginations(query: string, page: number) {
        return APIClient.ezoom.get(`/picture-validations?${query}&page=${page}`);
    }

    public static async subscriptions(query?: string) {
        return APIClient.ezoom.get(`/detailed-subscriptions${query && query !== undefined && query !== "" ? `?query=${query}` : ""}`);
    }

    public static async subscriptionsPagination(page: number, query?: string) {
        return APIClient.ezoom.get(`/detailed-subscriptions?page=${page}${query && query !== undefined && query !== "" ? `&query=${query}` : ""}`);
    }

    public static async renewSubscriptions(query?: string) {
        return APIClient.ezoom.get(`/subscription-renewal-checks${query && query !== undefined && query !== "" ? `?query=${query}` : ""}`);
    }

    public static async renewSubscriptionsPagination(page: number, query?: string) {
        return APIClient.ezoom.get(`/subscription-renewal-checks?page=${page}${query && query !== undefined && query !== "" ? `&query=${query}` : ""}`);
    }

    public static async subscription(id: string) {
        return APIClient.ezoom.get(`/subscriptions/${id}`);
    }

    public static async subscriptionTransactions(id: string) {
        return APIClient.ezoom.get(`/subscription-transactions/${id}`);
    }
}