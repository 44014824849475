import Header from "../../components/NavBar/Header";

export default function Admin() {
    return (
        <div className="min-h-screen bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
            </div>
        </div>
    );
}