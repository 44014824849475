export const Headers = [
    {
        id: "Société",
        col: "company",
    },
    {
        id: "Adresse Mail",
        col: "email",
    },
    {
        id: "Prénom",
        col: "firstname",
    },
    {
        id: "Nom",
        col: "lastname",
    }
];