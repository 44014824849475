import { Button, Checkbox, IconButton, List, ListItem, Popover, PopoverContent, PopoverHandler, Spinner, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";

import Pagination from "./Pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DefaultPictureEzoom from "../../assets/img/default-profile.png";
import PicturesAPI from "../../services/API/clients/PicturesAPI";
import ModalRefusePicture from "../Modals/ModalRefusePicture";
import ModalVisuOriginalPath from "../Modals/ModalVisuOriginalPath";
import { ElementPictureProps } from "../Interface/ElementPictureProps";
import WrittenDocumentsAPI from "../../services/API/clients/WrittenDocuments";

interface Props {
    header: { col: string; id: string }[];
    data: ElementPictureProps[];
    itemsPerPage: { current: number; last: number; totalItems: number; parPage: number };
    callPictures: Function;
    checked: string[];
    setChecked: Function;
}

const TablePictures: React.FC<Props> = ({ header, data, itemsPerPage, callPictures, checked, setChecked }) => {
    const { t } = useTranslation();
    const [constantData, setConstantData] = React.useState<boolean[]>([]);
    const [openRefuse, setOpenRefuse] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [currentId, setCurrentId] = React.useState<string>("");

    const handleMotif = async (motif: string, id: string) => {
        if (loading) return;
        const datas = {
            action: motif,
        };
        setLoading(true);
        const response = await PicturesAPI.validatePicture(id, datas);
        if (response.status === 200) {
            setLoading(false);
            setCurrentId("");
            callPictures(itemsPerPage.current);
        } else {
            setLoading(false);
            toast.error("Error");
        }
    };

    const ImageReturning = ({ keys, element }: { keys: number; element: any }) => {
        return (
            <button
                onClick={() => {
                    const oldConstantData = constantData;
                    oldConstantData[keys] = !oldConstantData[keys];
                    setConstantData([...oldConstantData]);
                }}
            >
                <div className="flex items-center overflow-hidden rounded-md" style={{ width: "75px", height: "75px" }}>
                    <img
                        alt="placeholder"
                        className="rounded-md h-full w-full object-cover object-center"
                        src={element?.picture?.publicPath ?? DefaultPictureEzoom}
                    />
                </div>
            </button>
        );
    };

    const ButtonsReturning = (id: { id: string }) => {
        if (loading && id.id === currentId) return <Spinner color="orange" />;
        return (
            <div className="flex gap-2 justify-center">
                <IconButton
                    onClick={() => {
                        setCurrentId(id.id);
                        handleMotif("accept", id.id);
                    }}
                    placeholder=""
                    color="green"
                    variant="outlined"
                    className="py-2 px-4"
                >
                    <FontAwesomeIcon icon="eye" />
                </IconButton>
                <IconButton
                    onClick={() => {
                        setCurrentId(id.id);
                        handleMotif("restrict", id.id);
                    }}
                    placeholder=""
                    color="green"
                    variant="outlined"
                    className="py-2 px-4"
                >
                    <FontAwesomeIcon icon="eye-slash" />
                </IconButton>
                <IconButton
                    onClick={() => {
                        setCurrentId(id.id);
                        handleMotif("nsfw", id.id);
                    }}
                    placeholder=""
                    color="yellow"
                    variant="outlined"
                    className="py-2 px-4"
                >
                    <FontAwesomeIcon icon="fire" />
                </IconButton>
                <IconButton
                    onClick={() => {
                        setCurrentId(id.id);
                        setOpenRefuse(true);
                    }}
                    placeholder=""
                    color="red"
                    variant="outlined"
                    className="py-2 px-4"
                >
                    <FontAwesomeIcon icon="x" />
                </IconButton>
            </div>
        );
    };

    const TypoReturning = ({
        element,
        column,
    }: {
        element: {
            id: string;
            picture: {
                id: string;
                publicPath?: string;
                minPath?: string;
                originalPath?: string;
            };
            reason: string;
        };
        column: { id: string; col: string };
    }) => {
        let content = element[column.col as keyof typeof element] ?? "/";
        if (typeof content === "object") {
            content = JSON.stringify(content);
        }

        return (
            <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                {content}
            </Typography>
        );
    };

    useEffect(() => {
        if (!openRefuse) {
            setCurrentId("");
            callPictures(itemsPerPage.current);
        }
    }, [openRefuse]);

    const openWrittenDocument = async (id: string, name: string, mimeType: string) => {
        const response = await WrittenDocumentsAPI.getDocument(id);
        if (response.status === 200) {
            window.open(response.body.path, "_blank");
        } else {
            toast.error("Error");
        }
    }

    return (
        <div className="table-container">
            <ModalRefusePicture open={openRefuse} setOpen={() => setOpenRefuse(false)} currentId={currentId} />
            <div className="w-full overflow-x-auto max-h-[52vh]">
                <table className="w-full min-w-[640px] table-auto overflow-x-scroll">
                    <thead>
                        <tr>
                            <th className="border-b border-blue-gray-50 py-3 px-5 text-center"></th>
                            {header?.map((column: { col: string; id: string }, index: number) => (
                                <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                    <Typography
                                        placeholder=""
                                        variant="small"
                                        className={`text-[11px] font-bold uppercase text-blue-gray-400 whitespace-nowrap ${
                                            index === header.length - 1 ? "text-center" : "text-left"
                                        }`}
                                    >
                                        {t(column.id)}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                    {data?.map((element: ElementPictureProps, key: number) => {
                        const className = `py-1 px-5 ${key === data.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                        const handleOpenVisuOrga = () => {
                            const oldConstantData = constantData;
                            oldConstantData[key] = !oldConstantData[key];
                            setConstantData([...oldConstantData]);
                        };

                        return (
                            <>
                                <ModalVisuOriginalPath open={constantData?.[key] ?? false} setOpen={handleOpenVisuOrga} picture={element} />
                                <tr key={element.id} className={`example-element-row`}>
                                    <td className={`py-5 border-b flex flex-row`} style={{ textAlign: "center" }}>
                                        <Checkbox
                                            crossOrigin=""
                                            color="orange"
                                            onChange={() => {
                                                const oldChecked = checked;
                                                if (oldChecked.includes(element.id)) {
                                                    oldChecked.splice(oldChecked.indexOf(element.id), 1);
                                                } else {
                                                    oldChecked.push(element.id);
                                                }
                                                setChecked([...oldChecked]);
                                            }}
                                            checked={checked?.includes(element.id)}
                                        />
                                    </td>
                                    {header?.map((column: { id: string; col: string }) => {
                                        return (
                                            <td key={column.id} className={className}>
                                                {column.id === "picture" && <ImageReturning keys={key} element={element} />}
                                                {column.id === "Validations" && <ButtonsReturning id={element.id} />}
                                                {column.id === "rightsAccords" && (
                                                    <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                                                        <Popover>
                                                            <PopoverHandler>
                                                                <Button placeholder="" color="orange" disabled={element?.picture?.writtenDocuments.length === 0}>{t("rightsAccords")}</Button>
                                                            </PopoverHandler>
                                                            <PopoverContent placeholder="" className="bg-white opacity-100 z-10 p-0">
                                                                <List placeholder="">
                                                                    {element?.picture?.writtenDocuments?.map((accord) => (
                                                                        <ListItem placeholder="" className="text-black" onClick={() => openWrittenDocument(accord.id, accord.name, accord.mime)}>{accord.name}</ListItem>
                                                                    ))}
                                                                </List>
                                                            </PopoverContent>
                                                        </Popover>
                                                    </Typography>
                                                )}
                                                {column.id === "picture[id]" && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{element.picture.id}</Typography>}
                                                {column.id === "owner" && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{element.picture?.owner?.fullname}</Typography>}
                                                {column.id !== "owner" && column.id !== "picture[id]" && column.id !== "picture" && column.id !== "Validations" && column.id !== "rightsAccords" && (
                                                    <TypoReturning element={element} column={column} />
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </>
                        );
                    })}

                    </tbody>
                </table>
            </div>
            <Pagination
                handlePageChange={callPictures}
                itemsPerPage={itemsPerPage.parPage}
                currentPage={itemsPerPage.current}
                totalItems={itemsPerPage.totalItems}
                startIndex={itemsPerPage.current * itemsPerPage.parPage - itemsPerPage.parPage}
                endIndex={
                    itemsPerPage.current * itemsPerPage.parPage - 1 > itemsPerPage.totalItems
                        ? itemsPerPage.totalItems - 1
                        : itemsPerPage.current * itemsPerPage.parPage - 1
                }
            />
        </div>
    );
};

export default TablePictures;
