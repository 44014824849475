import { useTranslation } from "react-i18next";
import Header from "../../../components/NavBar/Header";

export default function Page404() {
    document.title = "ezoom | 404";
    const { t } = useTranslation();

    return (
        <>
            <Header />
            <div className="mt-12 mx-auto mb-0 relative w-10/12 max-w-7xl font-extralight text-montserrat pb-[280px] md:pb-28" style={{ minWidth: '300px', height: 'calc(100vh - 215px)' }}>
                <div className="absolute top-1/3 md:top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 z-20">
                    <h1 className="font-sans text-9xl font-semibold text-center">404</h1>
                    <p className="text-center">{t("pagenotfound")}</p>
                </div>
                <div className="grid grid-cols-2 grid-rows-2 h-full opacity-20 gap-5">
                    {["https://cdn.pixabay.com/photo/2023/07/19/18/56/japanese-beetle-8137606_1280.png", "https://cdn.pixabay.com/photo/2023/06/01/17/59/insect-8034046_1280.jpg", "https://cdn.pixabay.com/photo/2023/05/23/18/12/hummingbird-8013214_1280.jpg", "https://cdn.pixabay.com/photo/2023/05/30/15/12/blue-butterfuly-8028888_1280.jpg"].map((image, index) => (
                        <div key={index} style={{ backgroundImage: `url('${image}')` }} className="rounded-xl bg-cover bg-center"></div>
                    ))}
                </div>
            </div>
        </>
    );
}
