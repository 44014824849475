import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Card, CardBody, Typography } from "@material-tailwind/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Header from "../../components/NavBar/Header";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import { HeaderDetails } from "./HeaderDetails";
import TransactionAPI from "../../services/API/clients/TransactionAPI";

export default function TransactionDetails() {
    const { t } = useTranslation();
    const { subscription, order } = useParams();
    const [transaction, setTransaction] = useState<{
        id: string,
        subscription?: {
            id: string
        },
        pictureOrder?: {
            id: string
        },
        status: string,
        internalId: string,
        createdAt: string,
        paymentMethod: string,
        paymentMethodName: string,
        displayText: string,
        amount: number,
        renewal: boolean
    }>({
        id: "",
        status: "",
        internalId: "",
        createdAt: "",
        paymentMethod: "",
        paymentMethodName: "",
        displayText: "",
        amount: 0,
        renewal: false,
    });

    const fetchData = async () => {
        if (!subscription) {
            if (!order) return;
            const response = await TransactionAPI.orders(order);
            if (response.status === 200) {
                setTransaction(response.body);
            }
        } else {
            const response = await TransactionAPI.subscriptions(subscription);
            if (response.status === 200) {
                setTransaction(response.body);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [subscription, order]);

    const ReturnKindOfTransaction = () => {
        if (transaction.subscription) {
            return (
                <tr>
                    <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                        <Typography
                            placeholder=""
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70 rounded-tl"
                        >
                            {t("subscription")}
                        </Typography>
                    </th>
                    <td className="p-4 border-b border-blue-gray-100/80">
                        <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                            <a className="underline" href={`/subscription/${transaction.subscription.id}`} target="_blank" rel="noreferrer">
                                {transaction.subscription.id}
                            </a>
                        </Typography>
                    </td>
                </tr>
            );
        }
        if (transaction.pictureOrder) {
            return (
                <tr>
                    <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                        <Typography
                            placeholder=""
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70 rounded-tl"
                        >
                            {t("order")}
                        </Typography>
                    </th>
                    <td className="p-4 border-b border-blue-gray-100/80">
                        <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                            <a className="underline" href={`/order/${transaction.pictureOrder.id}`} target="_blank" rel="noreferrer">
                                {transaction.pictureOrder.id}
                            </a>
                        </Typography>
                    </td>
                </tr>
            );
        }
        return (
            <tr>
                <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                    <Typography
                        placeholder=""
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70 rounded-tl"
                    >
                        {t("transaction")}
                    </Typography>
                </th>
                <td className="p-4 border-b border-blue-gray-100/80">
                    <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                        {transaction.id}
                    </Typography>
                </td>
            </tr>
        );
    }

    return (
        <div className="min-h-screen h-full bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage>
                        <FontAwesomeIcon icon="credit-card" className="text-orange-500" /> {t("transaction")}
                    </TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                    <div className="w-full h-full max-w-7xl mx-auto px-2 md:px-8 flex flex-col gap-4 md:gap-0 md:flex-row flex-wrap">
                        <div className="w-full md:pr-2 md:pb-2 h-full">
                            <Card placeholder="" className="w-full h-full flex flex-col justify-center">
                                <table className="h-full w-full table-auto text-left">
                                    <ReturnKindOfTransaction />
                                    {HeaderDetails.map((data) => {
                                        return (
                                            <tr key={data.id}>
                                                <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                                    <Typography
                                                        placeholder=""
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal leading-none opacity-70 rounded-tl"
                                                    >
                                                        {t(data.id)}
                                                    </Typography>
                                                </th>
                                                <td className="p-4 border-b border-blue-gray-100/80">
                                                    <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                                                        {data.id === "internalId" && (
                                                            <a className="underline" href={`https://test.saferpay.com/BO/Commerce/JournalDetail?gxid=${transaction[data.id as keyof typeof transaction]}`} target="_blank" rel="noreferrer">
                                                                {(transaction[data.id as keyof typeof transaction] ?? "").toString()}
                                                            </a>
                                                        )}
                                                        {data.id === "createdAt" && moment((transaction[data.id as keyof typeof transaction] ?? "").toString()).format("DD/MM/YYYY HH:mm")}
                                                        {(data.id !== "internalId" && data.id !== "createdAt") && (t((transaction[data.id as keyof typeof transaction] ?? "").toString()) ?? "/")}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </table>
                            </Card>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
