import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { Input } from "@material-tailwind/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CategoriesAPI from "../../services/API/clients/CategoriesAPI";
import KeywordsAPI from "../../services/API/clients/KeywordsAPI";
import StylesAPI from "../../services/API/clients/StylesAPI";
import TypesAPI from "../../services/API/clients/TypesAPI";
import ModalWrapper from "./ModalWrapper";

export default function ModalAddGestion({
    open,
    setOpen,
    fetchData,
    type
}: Readonly<{
    open: boolean;
    setOpen: () => void;
    fetchData: Function,
    type: string
}>){
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<{
        enumLabel: string,
        frLabel: string,
        enLabel: string
     }>({
        enumLabel: "",
        frLabel: "",
        enLabel: ""
    });

    const handleSubmit = async () => {
        if (loading) return;
        if (data.enumLabel === "") {
            toast.warning(t("enumLabelRequired"));
        } else if (data.frLabel === "") {
            toast.warning(t("frLabelRequired"));
        } else if (data.enLabel === "") {
            toast.warning(t("enLabelRequired"));
        } else {
            setLoading(true);
            const datas = {
                enumValue: data.enumLabel,
                frLabel: data.frLabel,
                enLabel: data.enLabel
            }
            let response
            if (type === "category")
                response = await CategoriesAPI.newCats(datas);
            else if (type === "types")
                response = await TypesAPI.newType(datas);
            else if (type === "styles")
                response = await StylesAPI.newStyles(datas);
            else if (type === "keyword")
                response = await KeywordsAPI.newKeyword(datas);
            else {
                setLoading(false);
                return
            }
            if (response.status === 201) {
                toast.success(t(type+"Created"));
                fetchData();
                setLoading(false);
                setData({ enumLabel: "", frLabel: "", enLabel: "" });
                setOpen();
            } else {
                toast.error(t(type+"Error"));
                setLoading(false);
            }
        }

    }

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                    {t(type+"Add")}
                </Dialog.Title>
                <FontAwesomeIcon
                    icon="x"
                    onClick={() => {
                        setOpen();
                    }}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer transition-all"
                />

                <div className="flex flex-col gap-3 mt-6">
                    {["enumLabel", "frLabel", "enLabel"].map((label) => (
                        <Input
                            key={label}
                            crossOrigin=""
                            color="orange"
                            type="text"
                            onChange={(e) => setData({ ...data, [label]: e.target.value })}
                            value={data[label as keyof typeof data]}
                            label={t(label)}
                        />
                    ))}
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    <button
                        type="button"
                        className="rounded-full text-white bg-orange-500 hover:bg-orange-200 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => handleSubmit()}
                    >
                        {loading ? <FontAwesomeIcon icon="spinner" spin /> : t("create")}
                    </button>
                    <button
                        type="button"
                        className="rounded-full text-black border bg-white hover:bg-gray-50 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("cancel")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
