import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Input, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function PopulardDate({
    populars,
    setPopulars,
    isBonus,
    type
}: Readonly<{ populars: { day: number | null; score: number }[]; setPopulars: Function; isBonus: boolean, type: string }>) {
    const { t } = useTranslation();

    const textToDisplay = (index: number) => {
        if (index === 0) return t("FROM_PUBLICATION");
        else if (index === populars.length - 1) return t("BEGIN_FROM", { from: populars[index - 1].day });
        else return t("TO_N_DAY", { from: populars[index - 1].day });
    };

    if (populars.length === 0) {
        setPopulars([{ day: null, score: 0 }]);
    }

    return (
        <div className="flex flex-col gap-4 w-full">
            {populars.length > 0 && (
                <>
                    {populars.length > 1 ? (
                        populars.map((popular, index) => (
                            <Card placeholder="" key={popular.day} className="p-4 relative">
                                <FontAwesomeIcon
                                    icon="trash"
                                    className="absolute top-2 right-2 cursor-pointer bg-red-500 p-2 rounded text-white text-xs"
                                    onClick={() => {
                                        const newPopulars = [...populars];
                                        newPopulars.splice(index, 1);
                                        setPopulars(newPopulars);
                                    }}
                                />
                                <Typography placeholder="" variant="h6">
                                    {textToDisplay(index)}
                                </Typography>
                                {index !== populars.length - 1 && (
                                    <div className="flex flex-row items-end gap-4">
                                        <Typography placeholder="" variant="h6" className="whitespace-nowrap">
                                            {t("TO")}
                                        </Typography>
                                        <Input
                                            crossOrigin=""
                                            type="text"
                                            variant="static"
                                            name={`${type}-day` + index}
                                            onBlur={(e) => {
                                                if (index === 0) {
                                                    const newPopulars = [...populars];
                                                    newPopulars[0].day = Number(e.target.value);
                                                    setPopulars(newPopulars);
                                                } else {
                                                    const previousPopular = populars[index - 1];
                                                    if (previousPopular.day && previousPopular.day !== undefined && populars[index] && Number(e.target.value) >= previousPopular?.day) {
                                                        const newPopulars = [...populars];
                                                        newPopulars[index].day = Number(e.target.value);
                                                        setPopulars(newPopulars);
                                                    } else 
                                                        toast.error(t("DAY_MUST_BE_GREATER_THAN_PREVIOUS_DAY"));
                                                }
                                            }}
                                            defaultValue={popular.day ?? ""}
                                        />
                                        <Typography placeholder="" variant="h6" className="whitespace-nowrap">
                                            {t("DAY")}
                                        </Typography>
                                    </div>
                                )}
                                <div className="flex flex-row items-end gap-4">
                                    <FontAwesomeIcon icon={isBonus ? "plus" : "minus"} className="-mt-2" />
                                    <Input
                                        crossOrigin=""
                                        type="text"
                                        variant="static"
                                        name={`${type}-score` + index}
                                        value={popular.score}
                                        onChange={(e) => {
                                            const newPopulars = [...populars];
                                            newPopulars[index].score = Number(e.target.value);
                                            setPopulars(newPopulars);
                                        }}
                                    />
                                    <Typography placeholder="" variant="h6">
                                        {t("PTS")}
                                    </Typography>
                                </div>
                            </Card>
                        ))
                    ) : (
                        <Card placeholder="" className="p-4 relative">
                            <Typography placeholder="" variant="h6">
                                {t("FROM_PUBLICATION")}
                            </Typography>
                            <div className="flex flex-row items-end gap-4">
                                <FontAwesomeIcon icon={isBonus ? "plus" : "minus"}  />
                                <Input
                                    crossOrigin=""
                                    type="text"
                                    variant="static"
                                    name={`score${type}--62`}
                                    value={populars[0].score}
                                    onChange={(e) => {
                                        const newPopulars = [...populars];
                                        newPopulars[0].score = Number(e.target.value);
                                        setPopulars(newPopulars);
                                    }}
                                />
                                <Typography placeholder="" variant="h6">
                                    {t("PTS")}
                                </Typography>
                            </div>
                        </Card>
                    )}
                </>
            )}
            <Button
                placeholder=""
                variant="outlined"
                color="orange"
                onClick={() => {
                    const oldPopulars = [...populars];
                    // I would like to add a new day just before the last day
                    const newDay = oldPopulars[oldPopulars.length - 1].day;
                    oldPopulars.push({ day: newDay ? newDay + 1 : 1, score: 0 });
                    setPopulars(oldPopulars);
                }}
            >
                {t("ADD")}
            </Button>
        </div>
    );
}
