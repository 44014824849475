export const HeaderDetails = [
    {
        id: "id",
        col: "id"
    },
    {
        id: "subscriptionId",
        col: "subscriptionId"
    },
    {
        id: "owner",
        col: "owner.id"
    },
    {
        id: "plan",
        col: "plan"
    },
    {
        id: "status",
        col: "status"
    },
    {
        id: "period",
        col: "period"
    },
    {
        id: "createdAt",
        col: "createdAt"
    },
]