import { Button, Input, Typography } from "@material-tailwind/react";
import AppSettingsAPI from "../../../services/API/clients/AppSettingsAPI";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import PopulardDate from "../../../components/Cards/PopularsDate";

interface PopularType {
    popularBonusPaid: { day: number | null; score: number }[];
    setPopularBonusPaid: Function;
    popularBonusStandard: { day: number | null; score: number }[];
    setPopularBonusStandard: Function;
    popularMalusPaid: { day: number | null; score: number }[];
    setPopularMalusPaid: Function;
    popularMalusStandard: { day: number | null; score: number }[];
    setPopularMalusStandard: Function;
    popularMinFavorites: number;
    setPopularMinFavorites: Function;
    popularMinViews: number;
    setPopularMinViews: Function;
    fetchData: () => Promise<void>;
}

export default function Populars({
    popularBonusPaid,
    setPopularBonusPaid,
    popularBonusStandard,
    setPopularBonusStandard,
    popularMalusPaid,
    setPopularMalusPaid,
    popularMalusStandard,
    setPopularMalusStandard,
    popularMinFavorites,
    setPopularMinFavorites,
    popularMinViews,
    setPopularMinViews,
    fetchData,
}: Readonly<PopularType>) {
    const { t } = useTranslation();

    const handleSave = async () => {
        const response = await Promise.all([
            AppSettingsAPI.modifAppSetting("POPULAR_BONUS_PAID", {
                value: popularBonusPaid
                    .slice(0, -1)
                    .map((element) => ({ ...element }))
                    .concat({ day: null, score: popularBonusPaid.slice(-1)[0].score }),
            }),
            AppSettingsAPI.modifAppSetting("POPULAR_BONUS_STANDARD", {
                value: popularBonusStandard
                    .slice(0, -1)
                    .map((element) => ({ ...element }))
                    .concat({ day: null, score: popularBonusStandard.slice(-1)[0].score }),
            }),
            AppSettingsAPI.modifAppSetting("POPULAR_MALUS_PAID", {
                value: popularMalusPaid
                    .slice(0, -1)
                    .map((element) => ({ ...element }))
                    .concat({ day: null, score: popularMalusPaid.slice(-1)[0].score }),
            }),
            AppSettingsAPI.modifAppSetting("POPULAR_MALUS_STANDARD", {
                value: popularMalusStandard
                    .slice(0, -1)
                    .map((element) => ({ ...element }))
                    .concat({ day: null, score: popularMalusStandard.slice(-1)[0].score }),
            }),
            AppSettingsAPI.modifAppSetting("POPULAR_MIN_FAVORITES", { value: popularMinFavorites }),
            AppSettingsAPI.modifAppSetting("POPULAR_MIN_VIEWS", { value: popularMinViews }),
        ]);
        if (response.some((res) => res.status !== 200)) {
            toast.error(t("POPULAR_SAVED_ERROR"));
        } else {
            toast.success(t("POPULAR_SAVED_SUCCESSFULLY"));
            await fetchData();
        }
    };

    return (
        <div>
            <Typography placeholder="" variant="h5">
                {t("POPULAR")}
            </Typography>
            <Typography placeholder="" variant="small">
                {t("POPULAR_d")}
            </Typography>
            <div>
                <div className="flex-col lg:flex-row flex justify-between gap-4 mt-4">
                    <div className="w-full">
                        <Typography placeholder="" variant="h6">
                            {t("POPULAR_MIN_FAVORITES")}
                        </Typography>
                        <div className="my-2">
                            <Input
                                variant="static"
                                crossOrigin=""
                                placeholder=""
                                value={popularMinFavorites}
                                onChange={(e) => setPopularMinFavorites(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="w-full">
                        <Typography placeholder="" variant="h6">
                            {t("POPULAR_MIN_VIEWS")}
                        </Typography>
                        <div className="my-2">
                            <Input
                                variant="static"
                                crossOrigin=""
                                placeholder=""
                                value={popularMinViews}
                                onChange={(e) => setPopularMinViews(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex-col lg:flex-row flex justify-between gap-4 mt-4">
                    <div className="w-full">
                        <Typography placeholder="" variant="h6">
                            {t("POPULAR_BONUS_PAID")}
                        </Typography>
                        <div className="my-2">
                            <PopulardDate isBonus={true} populars={popularBonusPaid} setPopulars={setPopularBonusPaid} type="popularBonusPaid" />
                        </div>
                    </div>
                    <div className="w-full">
                        <Typography placeholder="" variant="h6">
                            {t("POPULAR_BONUS_STANDARD")}
                        </Typography>
                        <div className="my-2 w-full">
                            <PopulardDate isBonus={true} populars={popularBonusStandard} setPopulars={setPopularBonusStandard} type="popularBonusStandard" />
                        </div>
                    </div>
                </div>
                <div className="flex-col lg:flex-row flex justify-between gap-4 mt-4">
                    <div className="w-full">
                        <Typography placeholder="" variant="h6">
                            {t("POPULAR_MALUS_PAID")}
                        </Typography>
                        <div className="my-2">
                            <PopulardDate isBonus={false} populars={popularMalusPaid} setPopulars={setPopularMalusPaid} type="popularDateMalusPaid" />
                        </div>
                    </div>
                    <div className="w-full">
                        <Typography placeholder="" variant="h6">
                            {t("POPULAR_MALUS_STANDARD")}
                        </Typography>
                        <div className="my-2">
                            <PopulardDate isBonus={false} populars={popularMalusStandard} setPopulars={setPopularMalusStandard} type="popularMalusStandard" />
                        </div>
                    </div>
                </div>
                <Button onClick={handleSave} placeholder="" className="w-full mt-4">
                    {t("SAVE")}
                </Button>
            </div>
        </div>
    );
}
