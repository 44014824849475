export const HeadersRenew = [
    {
        id: "createdAt",
        col: "createdAt"
    },
    {
        id: "renewedSubscriptions",
        col: "renewedSubscriptions"
    },
    {
        id: "failedRenewals",
        col: "failedRenewals"
    },
]