import APIClient from "../APIClients";

export default class KeywordsAPI {
    public static async getAll() {
        return APIClient.ezoom.get(`/keywords`);
    }

    public static async newKeyword(data: {}) {
        return APIClient.ezoom.post(`/keywords`, data);
    }

    public static async deleteKeyword(id: string) {
        return APIClient.ezoom.delete(`/keywords/${id}`);
    }

    public static async updateKeyword(id: string, data: {}) {
        return APIClient.ezoom.put(`/keywords/${id}`, data);
    }
}