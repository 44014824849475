import axios from "axios";
import { Storage } from "../storage";
import { toast } from "react-toastify";

export const refreshAuthLogic = (baseUrl: string) => async (failedRequest: { response: { config: { headers: { [x: string]: string } } } }) => {
    const tokenRefreshResponse = await axios.post(
        baseUrl,
        {},
        {
            headers: {
                Authorization: `Bearer ${Storage.getRefreshToken()}`,
            },
            validateStatus: function (status) {
                console.log(status)
                if (status === 401) {
                    toast.error("Votre session a expiré, veuillez vous reconnecter");
                    Storage.emptyStorage();
                    window.location.href = "/";
                }
                return status < 400;
            },
        }
    );
    Storage.setToken(tokenRefreshResponse.data.access_token);
    Storage.setRefreshToken(tokenRefreshResponse.data.refresh_token);
    failedRequest.response.config.headers["Authorization"] = "Bearer " + tokenRefreshResponse.data.access_token;
    return Promise.resolve();
};
