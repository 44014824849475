import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Validation } from "../../components/Interface/PictureResearch";
import TablePicturesValidations from "../../components/Tables/TablePictureValidations";
import PicturesAPI from "../../services/API/clients/PicturesAPI";
import { HeaderValidation } from "./HeaderValidation";

export default function History() {
    const { t } = useTranslation();
    const [pictures, setPictures] = useState<{
        pagination: { current: number, last: number, totalItems: number, parPage: number},
        data: Validation[]
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    });

    const getList = async () => {
        const response = await PicturesAPI.historyValidationPictures();
        if (response.status === 200) {
            setPictures(response.body);
        }
    }

    useEffect(() => {
        getList()
    }, [])

    const callPictures = async (page: number) => {
        const response = await PicturesAPI.historyValidationPicturesPaginations(page);
        if (response.status === 200) {
            setPictures(response.body);
        }
    }

    return (
        <>
            {pictures?.pagination.totalItems > 0 ? (
                <div className="w-full max-w-7xl mx-auto flex flex-col gap-4">
                    <TablePicturesValidations header={HeaderValidation} data={pictures?.data} itemsPerPage={pictures?.pagination} callPictures={callPictures} />
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                    <FontAwesomeIcon icon="images" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                    <Typography placeholder="" className="text-base text-gray-700 mb-4">{t("noPictures")}</Typography>
                </div>
            )}
        </>
    )
}