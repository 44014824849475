export const Headers = [
    {
        id: "ID",
        col: "id",
    },
    {
        id: "code",
        col: "code"
    },
    {
        id: "maxUsage",
        col: "maxUsage",
    },
    {
        id: "discountPct",
        col: "discountPct",
    },
    {
        id: "usages",
        col: "usages",
    }
];