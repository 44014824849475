import { Dialog } from "@headlessui/react";
import ModalWrapper from "./ModalWrapper";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "@material-tailwind/react";
import { useState } from "react";
import { Storage } from "../../services/storage";
import { toast } from "react-toastify";
import Auth from "../../services/API/clients/AuthAPI";

export default function Modal2fa({ open, setOpen, token }: Readonly<{ open: boolean; setOpen: () => void; token: string }>) {
    const { t } = useTranslation();
    const [code, setCode] = useState("");

    const handleConnect = async () => {
        if (code === "") return;
        if (token === "") return;

        const datas = {
            token: token,
            code: code
        }

        const response = await Auth.connect2fa(datas)
        if (response.status === 200 || response.status === 201) {
            if (response.body.roles.includes("role:admin")) {
                Storage.setToken(response.body.token);
                Storage.setRefreshToken(response.body.refresh_token);
                toast.success(t("signin_success"));
                window.location.href = "/admin";
            } else {
                toast.error(t("signin_error"));
                setOpen();
            }
        } else if (response.body.detail === "Invalid TOTP code") {
            toast.warning(t("twofactor_step4error"));
        } else {
            toast.error(t("expired_token"));
            setOpen();
        }
    }

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                    {t("twofactor")}
                </Dialog.Title>
                <FontAwesomeIcon
                    icon="x"
                    onClick={() => {
                        setOpen();
                    }}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer transition-all"
                />
                <div className="mt-6">
                    <p className="text-lg text-center text-gray-500 mb-4">{t("twofactor_step3")}</p>
                </div>

                <Input crossOrigin="" color="orange" onChange={(e) => setCode(e.target.value)} label="code" />

                <div className="flex justify-center gap-4 mt-6">
                    <button
                        type="button"
                        className="rounded-full text-white bg-orange-500 hover:bg-orange-200 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => handleConnect()}
                    >
                        {t("signin")}
                    </button>
                    <button
                        type="button"
                        className="rounded-full text-black border bg-white hover:bg-gray-50 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("cancel")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
