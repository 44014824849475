import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/NavBar/Header";
import TableSupport from "../../components/Tables/TableSupport";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import ContactAPI from "../../services/API/clients/ContactAPI";
import { Headers } from "./Header";

export default function Support() {
    const { t } = useTranslation();
    const [list, setList] = React.useState<{
        pagination: {
            current: number,
            last: number,
            totalItems: number,
            parPage: number
        }
        data: []
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    
    });
    document.title = `ezoom | ${t("communications")}`;

    const getList = async () => {
        const response = await ContactAPI.support();
        setList(response.body);
    }

    useEffect(() => {
        getList()
    }, [])

    return (
        <div className="min-h-screen bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage><FontAwesomeIcon icon="envelope-open-text" className="text-orange-500" /> {t("communications")}</TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                    <div className="w-full max-w-7xl mx-auto md:px-8 flex flex-col gap-4">
                        <div className="w-full overflow-x-auto">
                            <TableSupport header={Headers} data={list?.data ?? []} itemsPerPage={list?.pagination?.parPage ?? 0} />
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
