import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import Header from "../../components/NavBar/Header";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import Withdraw from "./Withdraw";
import WithdrawHistory from "./WithdrawHistory";
import { WithdrawProps, WithdrawUser } from "../../components/Interface/Withdraw";
import { useEffect, useState } from "react";
import WithdrawAPI from "../../services/API/clients/WithdrawAPI";

export default function Pictures() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("pictures")}`;
    const [currentTab, setCurrentTab] = useState<string>("current");
    const [datas, setDatas] = useState<WithdrawUser[]>([]);
    const [datasHistory, setDatasHistory] = useState<WithdrawProps>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    });

    useEffect(() => {
        if (currentTab === "history") {
            fetchDataHistory();
        } else
            fetchData();
    }, [currentTab]);

    const fetchDataHistory = async () => {
        const response = await WithdrawAPI.getHistory();
        setDatasHistory(response.body);
    }

    const fetchData = async () => {
        const response = await WithdrawAPI.getAll();
        setDatas(response.body);
    }

    const callPictures = async (page: number) => {
        const response = await WithdrawAPI.getHistoryPagination(page);
        if (response.status === 200) {
            setDatasHistory(response.body);
        }
    }

    return (
        <div className="min-h-screen bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage>
                        <FontAwesomeIcon icon="credit-card" className="text-orange-500" /> {t("paiements")}
                    </TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                    <div className="w-full max-w-7xl mx-auto md:px-8 flex flex-col gap-4">
                        <Tabs value="current">
                            <TabsHeader placeholder="">
                                <Tab placeholder="" value="current" onClick={() => setCurrentTab("current")}>
                                    {t("waiting")}
                                </Tab>
                                <Tab placeholder="" value="history" onClick={() => setCurrentTab("history")}>
                                    {t("history")}
                                </Tab>
                            </TabsHeader>
                            <TabsBody placeholder="">
                                <TabPanel value="current" className="mt-2">
                                    <Withdraw fetchData={fetchData} datas={datas} />
                                </TabPanel>
                                <TabPanel value="history" className="mt-2">
                                    <WithdrawHistory fetchDataHistory={fetchDataHistory} callPictures={callPictures} datasHistory={datasHistory} />
                                </TabPanel>
                            </TabsBody>
                        </Tabs>
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
