import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Card, CardBody, IconButton, Typography } from "@material-tailwind/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Header from "../../components/NavBar/Header";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import OrderAPI from "../../services/API/clients/OrdersAPI";
import { HeaderDetails } from "./HeaderDetails";
import { toast } from "react-toastify";

export default function OrderDetail() {
    const { t } = useTranslation();
    const { id } = useParams();
    const [order, setOrder] = useState<{
        id: string,
        totalPrice: number,
        orderId: string,
        status: string,
        createdAt: string,
        contracts: {
            id: string,
            picture: {
              id: string,
              minPath: string,
              width: number
              height: number
            }
        }[]
    }>({
        id: "",
        totalPrice: 0,
        orderId: "",
        status: "",
        createdAt: "",
        contracts: []
    });
    const [orderTransaction, setOrderTransaction] = useState<{
        pagination: {
            current: number;
            last: number;
            totalItems: number;
            parPage: number;
        };
        data: {
            id: string
            status: string
            createdAt: string
            paymentMethod: string
            paymentMethodName: string
            displayText: string
            amount: number
            renewal: boolean
        }[];
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0,
        },
        data: [],
    });

    const fetchData = async () => {
        if (!id) return;
        const response = await OrderAPI.order(id);
        if (response.status === 200) {
            setOrder(response.body);
        }
    };

    const fetchSubs = async () => {
        if (!id) return;
        const response = await OrderAPI.orderTransaction(id);
        if (response.status === 200) {
            setOrderTransaction(response.body);
        }
    };

    const openQuittanceSell = async (id: string) => {
        
    }

    const openQuittanceBuy = async (id: string) => {
        const response = await OrderAPI.quittance(id);
        if (response.status === 200) {
            const url = window.URL.createObjectURL(response.body);
            window.open(url);
        } else {
            toast.error(t("errorQuittanceBuy"))
        }
    }

    const ReturnTypo = ({ element, column }: { element: any; column: any }) => {
        if (column.id === "createdAt") {
            return <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">{moment(element[column.id]).format("DD.MM.YYYY HH:mm")}</Typography>;
        } else if (column.id === "buyQuittance") {
            return <IconButton placeholder="" color="orange" size="sm" onClick={() => openQuittanceBuy(element.id)}>
                <FontAwesomeIcon icon="file" />
            </IconButton>;
        } else if (column.id === "sellQuittance") {
            return <IconButton placeholder="" color="orange" size="sm" onClick={() => openQuittanceSell(element.id)}>
                <FontAwesomeIcon icon="file" />
            </IconButton>;
        }   else {
            return <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">{t(element[column.id]) ?? "/"}</Typography>;
        }
    };

    useEffect(() => {
        fetchData();
        fetchSubs();
    }, [id]);

    return (
        <div className="min-h-screen h-full bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage>
                        <FontAwesomeIcon icon="cart-shopping" className="text-orange-500" /> {t("order")}
                    </TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                    <div className="w-full h-full max-w-7xl mx-auto px-2 md:px-8 flex flex-col gap-4 md:gap-0 md:flex-row flex-wrap">
                        <div className="w-full md:w-96 md:pr-2 md:pb-2 h-full">
                            <Card placeholder="" className="w-full h-full flex flex-col justify-center">
                                <table className="h-full w-full table-auto text-left">
                                    {HeaderDetails.map((data) => {
                                        return (
                                            <tr key={data.id}>
                                                <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                                    <Typography
                                                        placeholder=""
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal leading-none opacity-70 rounded-tl"
                                                    >
                                                        {t(data.id)}
                                                    </Typography>
                                                </th>
                                                <td className="p-4 border-b border-blue-gray-100/80">
                                                    <ReturnTypo element={order} column={data} />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </table>
                            </Card>
                            <Card placeholder="" className="w-full h-fit flex flex-col justify-start mt-4">
                                <CardBody placeholder="" className="text-left">
                                    <Typography placeholder="" variant="h4" color="blue-gray" className="mb-2 text-center">
                                        {t("contracts")}
                                    </Typography>
                                    <div className=" max-h-[360px] mt-4 overflow-y-auto">
                                        {order?.contracts.map((contract) => {
                                            return (
                                                <Card placeholder="" key={contract.id} className="border">
                                                    <CardBody placeholder="">
                                                        <Typography placeholder="" variant="h6" color="blue-gray" className="mb-2">
                                                            <Avatar placeholder="" variant="rounded" src={contract.picture.minPath} className="mr-2" />
                                                            {contract.picture.width}x{contract.picture.height}
                                                        </Typography>
                                                        <Typography placeholder="" color="blue-gray" className="mb-2 text-xs">
                                                            <b>{t("contractId")}</b> : {contract.id}
                                                        </Typography>
                                                        <Typography placeholder="" variant="small" color="blue-gray" className="mb-2 text-xs">
                                                        <b>{t("pictureId")}</b> : {contract.picture.id}<br />
                                                        </Typography>
                                                    </CardBody>
                                                </Card>
                                            );
                                        })}
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="w-full md:w-[calc(100%-24rem)] h-full md:pr-2 md:pb-2">
                            <Card placeholder="" className="w-full h-full flex flex-col justify-start">
                                <CardBody placeholder="" className="text-left">
                                    <Typography placeholder="" variant="h4" color="blue-gray" className="mb-2 text-center">
                                        {t("histotransactions")}
                                    </Typography>
                                    <div className="h-full max-h-[360px] mt-4 overflow-y-auto">
                                        {orderTransaction?.data.length > 0 ? (
                                            <div className="h-full flex flex-col gap-2">
                                                {orderTransaction?.data.map((sub) => (
                                                    <Card placeholder="" key={sub.createdAt} className="border">
                                                        <CardBody placeholder="">
                                                            <Typography placeholder="" variant="h5" color="blue-gray" className="mb-2">
                                                                {sub.renewal ? (
                                                                    <>{t("renew")}{" "}</>
                                                                ) :
                                                                    <>{sub.displayText} - {sub.paymentMethodName}{" "}</>
                                                                }
                                                                <FontAwesomeIcon
                                                                    icon={sub.status === "AUTHORIZED" ? "check" : "x"}
                                                                    className={sub.status === "AUTHORIZED" ? "text-green-500" : "text-red-500"}
                                                                />
                                                            </Typography>
                                                            <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                                                                {moment(sub.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                                                            </Typography>
                                                            <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                                                                {t(sub.status)} - <b>{sub.amount}</b> {t("chf")}
                                                            </Typography>
                                                            <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                                                                <a className="underline" href={`/transaction/order/${sub.id}`}>{sub.id}</a>
                                                            </Typography>
                                                        </CardBody>
                                                    </Card>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                                                <FontAwesomeIcon icon="shopping-cart" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                                                <Typography placeholder="" className="text-base text-gray-700 mb-4">
                                                    {t("noSubs")}
                                                </Typography>
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
