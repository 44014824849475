import APIClient from "../APIClients";

export default class TypesAPI {
    public static async getAll() {
        return APIClient.ezoom.get(`/public/type-styles`);
    }

    public static async newType(data: {}) {
        return APIClient.ezoom.post(`/type-styles`, data);
    }

    public static async deleteType(id: string) {
        return APIClient.ezoom.delete(`/type-styles/${id}`);
    }

    public static async updateType(id: string, data: {}) {
        return APIClient.ezoom.put(`/type-styles/${id}`, data);
    }
}