import { Chip, IconButton, Spinner, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "./Pagination";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DefaultPictureEzoom from "../../assets/img/default-profile.png";
import UsersAPI from "../../services/API/clients/UsersAPI";
import UserActions from "./components/UsersActions";

interface Props {
    header: any;
    data: any;
    itemsPerPage: { current: number; last: number; totalItems: number; parPage: number };
    callUsers: (page: number) => void;
}

const TableUsers: React.FC<Props> = ({ header, data, itemsPerPage, callUsers }) => {
    const { t } = useTranslation();
    const [indexToEdit, setIndexToEdit] = React.useState<number | null>(null);
    const [visibleItemsEdit, setVisibleItemsEdit] = React.useState(data);
    const [constantData, setConstantData] = React.useState(data);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (data !== undefined && data.length > 0 && visibleItemsEdit.length === 0) {
            setVisibleItemsEdit(data);
            setConstantData(data);
        }
    }, [data]);

    return (
        <div className="table-container">
            <div className="w-full overflow-x-auto max-h-[52vh]">
                <table className="w-full min-w-[640px] table-auto overflow-x-scroll">
                    <thead>
                        <tr>
                            <th className="border-b border-blue-gray-50 py-3 px-5 text-center">
                                <Typography placeholder="" variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400">
                                    Actions
                                </Typography>
                            </th>
                            {header.map((column: any) => (
                                <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                    <Typography placeholder="" variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400 whitespace-nowrap">
                                        {column.id}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data !== undefined &&
                            data.map((element: any, key: number) => {
                                const className = `py-1 px-5 ${key === data.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                                return newFunction(
                                    element,
                                    constantData,
                                    visibleItemsEdit,
                                    setVisibleItemsEdit,
                                    header,
                                    className,
                                    key,
                                    indexToEdit,
                                    setIndexToEdit,
                                    loading,
                                    setLoading,
                                    itemsPerPage.current,
                                    callUsers
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <Pagination
                handlePageChange={callUsers}
                itemsPerPage={itemsPerPage.parPage}
                currentPage={itemsPerPage.current}
                totalItems={itemsPerPage.totalItems}
                startIndex={itemsPerPage.current * itemsPerPage.parPage - itemsPerPage.parPage}
                endIndex={
                    itemsPerPage.current * itemsPerPage.parPage - 1 > itemsPerPage.totalItems
                        ? itemsPerPage.totalItems - 1
                        : itemsPerPage.current * itemsPerPage.parPage - 1
                }
            />
        </div>
    );
};

function newFunction(
    element: any,
    constantData: any,
    storageUser: any,
    setStorageUser: Function,
    header: any,
    className: string,
    key: number,
    indexToEdit: number | null,
    setIndexToEdit: Function,
    loading: boolean,
    setLoading: Function,
    currentPage: number,
    callUsers: (page: number) => void
) {
    const updateMyUser = async (storageUser: any) => {
        setLoading(true);
        if (storageUser === undefined) return;
        if (storageUser[key] === undefined) return;

        const datas = {
            firstname: storageUser[key].firstname,
            lastname: storageUser[key].lastname,
            email: storageUser[key].email,
            phone: storageUser[key].phone,
            birthdate: storageUser[key].birthdate,
            company: storageUser[key].company,
            iban: storageUser[key].iban,
        };
        const response = await UsersAPI.modifyUser(storageUser[key].id, datas);
        if (response.status === 200 || response.status === 201) {
            toast.success("Utilisateur mis à jour avec succès");
            setIndexToEdit(null);
            setLoading(false);
            element.firstname = storageUser[key].firstname;
            element.lastname = storageUser[key].lastname;
            element.email = storageUser[key].email;
            element.phone = storageUser[key].phone;
            element.birthdate = storageUser[key].birthdate;
            element.company = storageUser[key].company;
            element.iban = storageUser[key].iban;
        } else if (response.status === 400) {
            toast.error(response.body.detail)
        } else {
            toast.error("Une erreur est survenue, veuillez réessayer plus tard");
        }
        if (storageUser[key].plan === "PREMIUM" || storageUser[key].plan === "PRO") {
            const data = {
                owner: `/api/users/${storageUser[key].id}`,
                plan: storageUser[key].plan,
                giftDuration: 30,
            };
            const resp = await UsersAPI.changePlan(data);
            if (resp.status === 200 || resp.status === 201) {
                toast.success("Plan mis à jour avec succès");
                setIndexToEdit(null);
                setLoading(false);
            } else {
                toast.error("Une erreur est survenue, veuillez réessayer plus tard");
            }
        }
    };

    if (storageUser[key] === undefined) return;
    return (
        <React.Fragment key={storageUser[key].id}>
            <tr
                className={`example-storageUser-row ${
                    storageUser[key].deletedAt !== "1970-01-01T00:00:00.000Z" && storageUser[key].deletedAt !== undefined && "bg-gray-200"
                }`}
            >
                <td className={`py-5 border-b flex flex-row`} style={{ textAlign: "center" }}>
                    <UserActions id={storageUser[key].id} currentPage={currentPage} reloadDatas={callUsers} enabled={element.enabled} />
                    <div className="flex flex-row gap-2">
                        <IconButton
                            placeholder=""
                            className="ml-4"
                            color={indexToEdit === key ? "green" : "purple"}
                            size="sm"
                            onClick={() => (indexToEdit === key ? updateMyUser(storageUser) : setIndexToEdit(key))}
                        >
                            {loading && indexToEdit === key ? <Spinner color="gray" /> : <FontAwesomeIcon icon={indexToEdit === key ? "check" : "edit"} />}
                        </IconButton>
                        {indexToEdit === key && (
                            <IconButton placeholder="" color="red" size="sm" onClick={() => setIndexToEdit(null)}>
                                <FontAwesomeIcon icon={"x"} />
                            </IconButton>
                        )}
                    </div>
                </td>
                {header.map((column: any, i: number) => {
                    return (
                        <td key={column.id} className={className}>
                            {column.id === "PdP" && (
                                <div className="flex items-center overflow-hidden rounded-md" style={{ width: "36px", height: "36px" }}>
                                    <img
                                        alt="placholder"
                                        className="rounded-md object-cover object-center"
                                        src={storageUser[key]?.portfolio?.picture ?? DefaultPictureEzoom}
                                    />
                                </div>
                            )}
                            {column.id === "Actif" && (
                                <>
                                    {indexToEdit === key ? (
                                        <Chip color={element[column.col] ? "green" : "red"} value={element[column.col] ? "Actif" : "Inactif"} className="text-center opacity-50 bg-opacity-50" />
                                    ) : (
                                        <Chip color={element[column.col] ? "green" : "red"} value={element[column.col] ? "Actif" : "Inactif"} className="text-center" />
                                    )}
                                </>
                            )}
                            {column.id === "ID" && (
                                <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 underline whitespace-nowrap"}>
                                    <a href={`/user/${element[column.col]}`}>{element[column.col]}</a>
                                </Typography>
                            )}
                            {column.id === "Pseudo" && (
                                <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                                    {element.portfolio?.name ?? "/"}
                                </Typography>
                            )}
                            {column.id === "Date de naissance" && (
                                <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                                    {indexToEdit === key ? (
                                        <input
                                            className="bg-white px-2 py-1 rounded border"
                                            type="date"
                                            value={storageUser[key][column.col]}
                                            onChange={(e) => {
                                                storageUser[key][column.col] = e.target.value;
                                                setStorageUser([...storageUser]);
                                            }}
                                        />
                                    ) : (
                                        moment(element[column.col]).format("DD.MM.YYYY")
                                    )}
                                </Typography>
                            )}
                            {column.id === "Type de compte" && (
                                <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                                    {indexToEdit === key ? (
                                        <select
                                            className="bg-white px-2 py-2 rounded border"
                                            defaultValue={storageUser[key][column.col]}
                                            onChange={(e) => {
                                                storageUser[key][column.col] = e.target.value;
                                                setStorageUser([...storageUser]);
                                            }}
                                        >
                                            <option value="PRO" defaultChecked={storageUser[column.col] === "PRO"}>
                                                PRO
                                            </option>
                                            <option value="PREMIUM" defaultChecked={storageUser[column.col] === "PREMIUM"}>
                                                Premium
                                            </option>
                                            <option value="PHOTOGRAPHER" defaultChecked={storageUser[column.col] === "PHOTOGRAPHER"}>
                                                Photographe
                                            </option>
                                            <option value="USER" defaultChecked={storageUser[column.col] === "USER"}>
                                                Utilisateur
                                            </option>
                                        </select>
                                    ) : element[column.col] === "PRO" ? (
                                        "Pro"
                                    ) : element[column.col] === "PREMIUM" ? (
                                        "Premium"
                                    ) : element[column.col] === "PHOTOGRAPHER" ? (
                                        "Photographe"
                                    ) : (
                                        "Utilisateur"
                                    )}
                                </Typography>
                            )}
                            {column.id !== "Pseudo" && column.id !== "Date de naissance" && column.id !== "Type de compte" && column.id !== "PdP" && column.id !== "ID" && column.id !== "Actif" && (
                                <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrapw"}>
                                    {indexToEdit === key ? (
                                        <input
                                            className="bg-white px-2 py-1 rounded border"
                                            type="text"
                                            value={storageUser[key][column.col]}
                                            onChange={(e) => {
                                                storageUser[key][column.col] = e.target.value;
                                                setStorageUser([...storageUser]);
                                            }}
                                        />
                                    ) : (
                                        element[column.col] ?? "/"
                                    )}{" "}
                                </Typography>
                            )}
                        </td>
                    );
                })}
            </tr>
        </React.Fragment>
    );
}

export default TableUsers;
