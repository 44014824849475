import React from "react";
import { IconButton, Typography } from "@material-tailwind/react";

import Pagination from "./Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    header: any;
    data: any;
    itemsPerPage: number;
    onClickAction?: Function;
    onClickEdit?: Function;
}

const Table: React.FC<Props> = ({ header, data, itemsPerPage, onClickAction, onClickEdit }) => {
    const [currentPage, setCurrentPage] = React.useState(1);
    const totalItems = data.length;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems - 1);
    const visibleItems = data.slice(startIndex, endIndex + 1);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <div className="table-container max-h-[52vh]">
            <table className="w-full min-w-[640px] table-auto overflow-x-scroll">
                <thead>
                    <tr>
                        {header.map((column: any) => (
                            <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                <Typography placeholder="" variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400">
                                    {column.id}
                                </Typography>
                            </th>
                        ))}
                        {onClickAction !== undefined && 
                            <th className="border-b border-blue-gray-50 py-3 px-5 text-center">
                                <Typography placeholder="" variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400">
                                    Actions
                                </Typography>
                            </th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {visibleItems !== undefined &&
                        visibleItems.map((element: any, key: number) => {
                            const className = `py-3 px-5 ${key === visibleItems.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                            return newFunction(element, header, className, onClickAction, onClickEdit);
                        })}
                </tbody>
            </table>
            <Pagination
                handlePageChange={handlePageChange}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                totalItems={totalItems}
                startIndex={startIndex}
                endIndex={endIndex}
            />
        </div>
    );
};

export default Table;
function newFunction(element: any, header: any, className: string, onClickAction?: Function, onClickEdit?: Function) {
    return (
        <React.Fragment key={element.id}>
            <tr
                className={`example-element-row ${element.deletedAt !== "1970-01-01T00:00:00.000Z" && element.deletedAt !== undefined && "bg-gray-200"}`}
            >
                {header.map((column: any, i: number) => {
                    return (
                        <td key={column.id} className={className}>
                            <Typography
                                placeholder=""
                                variant="small"
                                color="blue-gray"
                                className={
                                    "text-gray-500"
                                }
                            >
                                {element[column.col] ?? "/"}
                            </Typography>
                        </td>
                    );
                })}
                {(onClickAction !== undefined || onClickEdit !== undefined) && 
                    <td className={className} style={{ textAlign: "center"}}>
                        {onClickAction !== undefined &&
                            <IconButton placeholder="" color="orange" size="sm" onClick={() => onClickAction(element["id"])}>
                                <FontAwesomeIcon icon="x" />
                            </IconButton>
                        }
                        {onClickEdit !== undefined &&
                            <IconButton placeholder="" className="ml-4" color="purple" size="sm" onClick={() => onClickEdit(element["id"])}>
                                <FontAwesomeIcon icon="edit" />
                            </IconButton>
                        }
                    </td>
                }
            </tr>
        </React.Fragment>
    );
}
