import APIClient from "../APIClients";

export default class WithdrawAPI {
    public static async getAll() {
        return APIClient.ezoom.get(`/non-withdrawn-sales`);
    }

    public static async getHistory() {
        return APIClient.ezoom.get(`/withdraw-history`);
    }

    public static async getHistoryPagination(page: number) {
        return APIClient.ezoom.get(`/withdraw-history?page=${page}`);
    }

    public static async newWithdraw(data: {}) {
        return APIClient.ezoom.post(`/export-sepa`, data);
    }

    public static async unWithdraw(id: string, data: {}) {
        return APIClient.ezoom.put(`/sales/${id}`, data);
    }

    public static async withdraw(data: {}) {
        return APIClient.ezoom.put(`/withdraw-sales`, data);
    }
}