import APIClient from "../APIClients";

export default class VouchersAPI {
    public static async getVouchers(page?: number) {
        return APIClient.ezoom.get(`/vouchers${page ? `?page=${page}` : ""}`);
    }

    public static async newVouchers(data: {}) {
        return APIClient.ezoom.post(`/vouchers`, data);
    }
}