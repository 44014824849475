import { useTranslation } from "react-i18next";
import AppSettingsAPI from "../../../services/API/clients/AppSettingsAPI";
import { Button, Input, Typography } from "@material-tailwind/react";
import { toast } from "react-toastify";

export default function Recents({
    recentGroupDays,
    setRecentGroupDays,
    fetchData,
}: Readonly<{ recentGroupDays: number; setRecentGroupDays: Function; fetchData: () => Promise<void> }>) {
    const { t } = useTranslation();

    const handleSave = async () => {
        if (!Number(recentGroupDays)) {
            toast.error(t("RECENT_GROUP_DAYS_must_be_number"));
            return;
        }
        if (Number(recentGroupDays) < 0) {
            toast.error(t("RECENT_GROUP_DAYS_must_be_positive"));
            return;
        }
        const datas = {
            value: Number(recentGroupDays),
        };
        const response = await AppSettingsAPI.modifAppSetting("RECENT_GROUP_DAYS", datas);
        if (response.status === 200) {
            toast.success(t("RECENT_GROUP_DAYS_updated"));
            await fetchData();
        } else {
            toast.error(t("RECENT_GROUP_DAYS_error"));
        }
    };

    return (
        <div>
            <Typography placeholder="" variant="h5">
                {t("RECENT_GROUP_DAYS")}
            </Typography>
            <Typography placeholder="" variant="small">
                {t("RECENT_GROUP_DAYS_d")}
            </Typography>
            <div>
                <Input
                    crossOrigin=""
                    variant="static"
                    placeholder=""
                    type="text"
                    value={recentGroupDays ?? 0}
                    onChange={(e) => {
                        setRecentGroupDays(e.target.value);
                    }}
                />
            </div>
            <Button onClick={handleSave} placeholder="" className="mt-4">
                {t("SAVE")}
            </Button>
        </div>
    );
}
