import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SubscriptionsAPI from "../../services/API/clients/SubscriptionsAPI";
import { IconButton, Input, Typography } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HeadersListing } from "./HeadersListing";
import TableSubs from "../../components/Tables/TableSubs";

export default function Listing() {
    const { t } = useTranslation();
    const [query, setQuery] = useState<string>("");
    const [subs, setSubs] = useState<{
        pagination: { current: number, last: number, totalItems: number, parPage: number},
        data: any[]
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    });

    const getList = async () => {
        const response = await SubscriptionsAPI.subscriptions();
        if (response.status === 200) {
            setSubs(response.body);
        }
    }

    useEffect(() => {
        getList()
    }, [])

    const callPictures = async (page: number) => {
        const response = await SubscriptionsAPI.subscriptionsPagination(page, query);
        if (response.status === 200) {
            setSubs(response.body);
        }
    }

    const handleResearchWithQuery = async () => {
        const response = await SubscriptionsAPI.subscriptions(query);
        if (response.status === 200) {
            setSubs(response.body);
        }
    }

    return (
        <>
            {subs?.pagination.totalItems > 0 ? (
                <div className="w-full max-w-7xl mx-auto flex flex-col gap-4">
                    <div className="flex flex-row items-center justify-between w-full gap-4">
                        <Input crossOrigin="" type="text" color="orange" className="bg-white" label={t("search")} value={query} onChange={(e) => setQuery(e.target.value)} />
                        <IconButton placeholder="" color="orange" onClick={() => handleResearchWithQuery()}>
                            <FontAwesomeIcon icon="paper-plane" />
                        </IconButton>
                    </div>
                    <TableSubs header={HeadersListing} data={subs?.data} itemsPerPage={subs?.pagination} callPictures={callPictures} />
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                    <FontAwesomeIcon icon="images" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                    <Typography placeholder="" className="text-base text-gray-700 mb-4">{t("noSubs")}</Typography>
                </div>
            )}
        </>
    )
}