import React from "react";
import { useTranslation } from "react-i18next";

export default function GradientButton({ text, disabled = false }: { text: string, disabled?: boolean }) {
    const { t } = useTranslation();

    return (
        <button
            className={`${disabled ? "border-2 border-transparent bg-gray-300 text-white" : "border-2 bg-orange-500 hover:bg-orange-200 border-transparent text-white hover:text-black"} inline w-full py-2 px-4 rounded-full flex text-center items-center justify-center`}
            style={{
                // backgroundImage: "linear-gradient(to right, #e98512, #F5BA56, #F5BA56, #fff)",
                // backgroundSize: "300% 100%",
                transition: "all .25s ease-in-out",
                // backgroundPosition: isHover ? "calc(100% + 6px)" : "-6px",
            }}
            disabled={disabled}
        >
            <span className="flex text-center">{t(text)}</span>
        </button>
    );
}
