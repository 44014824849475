export const HeaderOrder = [
    {
        id: "id",
        col: "id",
    },
    {
        id: "totalPrice",
        col: "totalPrice",
    },
    {
        id: "orderId",
        col: "orderId",
    },
    {
        id: "status",
        col: "status",
    },
    {
        id: "createdAt",
        col: "createdAt",
    },
    {
        id: "contracts",
        col: "contracts",
    }
];