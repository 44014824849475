import { Button, Typography } from "@material-tailwind/react";
import React from "react";

import Pagination from "./Pagination";

import { useTranslation } from "react-i18next";
import ModalListRenews from "../Modals/ModalListRenews";
import moment from "moment";

interface Props {
    header: { col: string; id: string }[];
    data: any[];
    itemsPerPage: { current: number; last: number; totalItems: number; parPage: number };
    callPictures: Function;
}

const TableRenew: React.FC<Props> = ({ header, data, itemsPerPage, callPictures }) => {
    const { t } = useTranslation();
    const [openRefuse, setOpenRefuse] = React.useState<boolean>(false);
    const [openDatas, setOpenDatas] = React.useState<
        {
            id: string;
            owner: string;
            plan: string;
            status: string;
            period: string;
            createdAt: string;
            giftDuration?: number;
        }[]
    >([]);

    const TransactionReturning = ({ element }: { element: {
        id: string;
        owner: string;
        plan: string;
        status: string;
        period: string;
        createdAt: string;
        giftDuration?: number;
    }[] }) => {
        return (
            <Button placeholder="" color="orange" onClick={() => {
                setOpenDatas(element);
                setOpenRefuse(!openRefuse);
            }}>
                {`${element.length}`}
            </Button>
        );
    };

    const TypoReturning = ({ element }: { element: string }) => {
        return (
            <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                {moment(element).format("DD.MM.YYYY HH:mm")}
            </Typography>
        );
    };

    return (
        <div className="table-container">
            <ModalListRenews open={openRefuse} setOpen={() => setOpenRefuse(!openRefuse)} datas={openDatas} />
            <div className="w-full overflow-x-auto max-h-[52vh]">
                <table className="w-full min-w-[640px] table-auto overflow-x-scroll">
                    <thead>
                        <tr>
                            {header?.map((column: { col: string; id: string }, index: number) => (
                                <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                    <Typography
                                        placeholder=""
                                        variant="small"
                                        className={`text-[11px] font-bold uppercase text-blue-gray-400 whitespace-nowrap ${
                                            index === header.length - 1 ? "text-center" : "text-left"
                                        }`}
                                    >
                                        {t(column.id)}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map(
                            (
                                element: {
                                    createdAt: string;
                                    failedRenewals: {
                                        id: string;
                                        owner: string;
                                        plan: string;
                                        status: string;
                                        period: string;
                                        createdAt: string;
                                        giftDuration?: number
                                    }[];
                                    renewedSubscriptions: {
                                        id: string;
                                        owner: string;
                                        plan: string;
                                        status: string;
                                        period: string;
                                        createdAt: string;
                                        giftDuration?: number
                                    }[];
                                },
                                key: number
                            ) => {
                                const className = `py-1 px-5 ${key === data.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                                return (
                                    <tr key={element.createdAt} className={`example-element-row`}>
                                        {header?.map((column: { id: string; col: string }) => {
                                            return (
                                                <td key={column.id} className={className}>
                                                    {column.id === "renewedSubscriptions" && <TransactionReturning element={element.renewedSubscriptions} />}
                                                    {column.id === "failedRenewals" && <TransactionReturning element={element.failedRenewals} />}
                                                    {column.id !== "renewedSubscriptions" && column.id !== "failedRenewals" && (
                                                        <TypoReturning element={element.createdAt} />
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                </table>
            </div>
            <Pagination
                handlePageChange={callPictures}
                itemsPerPage={itemsPerPage.parPage}
                currentPage={itemsPerPage.current}
                totalItems={itemsPerPage.totalItems}
                startIndex={itemsPerPage.current * itemsPerPage.parPage - itemsPerPage.parPage}
                endIndex={
                    itemsPerPage.current * itemsPerPage.parPage - 1 > itemsPerPage.totalItems
                        ? itemsPerPage.totalItems - 1
                        : itemsPerPage.current * itemsPerPage.parPage - 1
                }
            />
        </div>
    );
};

export default TableRenew;
