import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";

import Header from "../../components/NavBar/Header";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import TitlePage from "../../components/TitlePage";
import Picture from "./Picture";
import Validation from "./Validation";
import History from "./History";

export default function Pictures() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("pictures")}`;

    return (
        <div className="min-h-screen bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage>
                        <FontAwesomeIcon icon="images" className="text-orange-500" /> {t("pictures")}
                    </TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                    <div className="w-full max-w-7xl mx-auto md:px-8 flex flex-col gap-4">
                        <Tabs value="validate">
                            <TabsHeader placeholder="">
                                <Tab placeholder="" value="validate">
                                    {t("validate")}
                                </Tab>
                                <Tab placeholder="" value="search">
                                    {t("search")}
                                </Tab>
                                <Tab placeholder="" value="history">
                                    {t("history")}
                                </Tab>
                            </TabsHeader>
                            <TabsBody placeholder="">
                                <TabPanel value="validate" className="mt-2">
                                    <Validation />
                                </TabPanel>
                                <TabPanel value="search" className="mt-2">
                                    <Picture />
                                </TabPanel>
                                <TabPanel value="history" className="mt-2">
                                    <History />
                                </TabPanel>
                            </TabsBody>
                        </Tabs>
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
