export interface SubscriptionProps {
    id: string
    subscription: Subscription
    status: string
    internalId: string
    createdAt: string
    paymentMethod: string
    paymentMethodName: string
    displayText: string
    amount: number
    renewal: boolean
}
  
export interface Subscription {
    id: string
}

export const DEFAULT_DATA_SUBSCRIPTION: SubscriptionProps = {
    id: "",
    subscription: {
        id: ""
    },
    status: "",
    internalId: "",
    createdAt: "",
    paymentMethod: "",
    paymentMethodName: "",
    displayText: "",
    amount: 0,
    renewal: false
}