import APIClient from "../APIClients";

export default class NewsletterAPI {
    public static async news() {
        return APIClient.ezoom.get(`/newsletter`);
    }

    public static async subscribe(data: {}) {
        return APIClient.ezoom.post(`/public/newsletter`, data);
    }

    public static async unSubscribe(enumValue: string) {
        return APIClient.ezoom.delete(`/public/newsletter/${enumValue}`);
    }
}