export const HeadersListing = [
    {
        id: "id",
        col: "id"
    },
    {
        id: "owner",
        col: "owner"
    },
    {
        id: "plan",
        col: "plan"
    },
    {
        id: "status",
        col: "status"
    },
    {
        id: "period",
        col: "period"
    },
    {
        id: "createdAt",
        col: "createdAt"
    },
    {
        id: "subscriptionId",
        col: "subscriptionId"
    },
    {
        id: "lastTransaction",
        col: "lastTransaction"
    },
    {
        id: "initialTransaction",
        col: "initialTransaction"
    },
]