export const HeaderCardDetail = [
    {
        id: "Société",
        col: "company",
    },
    {
        id: "Prénom",
        col: "firstname",
    },
    {
        id: "Nom",
        col: "lastname",
    },
    {
        id: "Pseudo",
        col: "portfolio?.name",
    },
    {
        id: "Adresse Mail",
        col: "email",
    },
    {
        id: "Téléphone",
        col: "phone",
    },
    {
        id: "Date de naissance",
        col: "birthdate",
    },
    {
        id: "Pays",
        col: "country"
    },
    {
        id: "Adresse",
        col: "place"
    },
    {
        id: "IBAN",
        col: "iban",
    },
    {
        id: "SWIFT/BIC",
        col: "bic"
    }
];