import APIClient from "../APIClients";

export default class PicturesAPI {
    public static async validationPictures(query: string) {
        return APIClient.ezoom.get(`/picture-validations?${query}`);
    }

    public static async validationPicturesPaginations(query: string, page: number) {
        return APIClient.ezoom.get(`/picture-validations?reason=${query}&page=${page}`);
    }

    public static async historyValidationPictures() {
        return APIClient.ezoom.get(`/picture-validations-history`);
    }

    public static async historyValidationPicturesPaginations(page: number) {
        return APIClient.ezoom.get(`/picture-validations-history?page=${page}`);
    }

    public static async pictures(query?: string) {
        return APIClient.ezoom.get(`/pictures${query && query !== undefined && query !== "" ? `?query=${query}` : ""}`);
    }

    public static async picturesPagination(page: number, query?: string) {
        return APIClient.ezoom.get(`/pictures${query && query !== undefined && query !== "" ? `?query=${query}` : ""}${query && query !== undefined && query !== "" ? `&page=${page}` : `?page=${page}`}`);
    }

    public static async validatePicture(id: string, data: any) {
        return APIClient.ezoom.put(`/picture-validations/${id}`, data);
    }

    public static async getWrittenDocuments(id: string) {
        return APIClient.ezoom.get(`/written-documents/${id}`);
    }

    public static async deletePicture(id: string) {
        return APIClient.ezoom.delete(`/pictures/${id}`);
    }

    public static async putPicture(id: string, data: any) {
        return APIClient.ezoom.put(`/pictures/${id}`, data);
    }
}