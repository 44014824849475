import APIClient from "../APIClients";

export default class ServicesAPI {
    public static async getAll() {
        return APIClient.ezoom.get(`/public/service-types`);
    }

    public static async newService(data: {}) {
        return APIClient.ezoom.post(`/service-types`, data);
    }

    public static async deleteService(id: string) {
        return APIClient.ezoom.delete(`/service-types/${id}`);
    }

    public static async updateService(id: string, data: {}) {
        return APIClient.ezoom.put(`/service-types/${id}`, data);
    }
}