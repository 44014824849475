export const HeadersWithdraw = [
    {
        id: "ID",
        col: "id"
    },
    {
        id: "picture",
        col: "contracts"
    },
    {
        id: "saleId",
        col: "saleId"
    },
    {
        id: "netWorth",
        col: "netWorth"
    },
    {
        id: "createdAt",
        col: "createdAt"
    }
]

export const HeadersWithdrawHistory = [
    {
        id: "ID",
        col: "id"
    },
    {
        id: "picture",
        col: "contracts"
    },
    {
        id: "saleId",
        col: "saleId"
    },
    {
        id: "netWorth",
        col: "netWorth"
    },
    {
        id: "withdrawDate",
        col: "withdrawDate"
    },
    {
        id: "createdAt",
        col: "createdAt"
    }
]