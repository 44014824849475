import APIClient from "../APIClients";

export default class CategoriesAPI {
    public static async getAll() {
        return APIClient.ezoom.get(`/public/categories`);
    }

    public static async newCats(data: {}) {
        return APIClient.ezoom.post(`/categories`, data);
    }

    public static async deleteCats(id: string) {
        return APIClient.ezoom.delete(`/categories/${id}`);
    }

    public static async updateCats(id: string, data: {}) {
        return APIClient.ezoom.put(`/categories/${id}`, data);
    }
}