import APIClient from "../APIClients";

export default class TransactionAPI {
    public static async subscriptions(id: string) {
        return APIClient.ezoom.get(`/subscription-transactions/${id}`);
    }

    public static async orders(id: string) {
        return APIClient.ezoom.get(`/order-transactions/${id}`);
    }
}