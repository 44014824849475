export const Headers = [
    {
        id: "ID",
        col: "id",
    },
    {
        id: "Actif",
        col: "enabled",
    },
    {
        id: "PdP",
        col: "portfolio?.picture"
    },
    {
        id: "Prénom",
        col: "firstname",
    },
    {
        id: "Nom",
        col: "lastname",
    },
    {
        id: "Pseudo",
        col: "portfolio?.name",
    },
    {
        id: "Société",
        col: "company",
    },
    {
        id: "Date de naissance",
        col: "birthdate",
    },
    {
        id: "Adresse Mail",
        col: "email",
    },
    {
        id: "Téléphone",
        col: "phone",
    },
    {
        id: "IBAN",
        col: "iban",
    },
    {
        id: "Type de compte",
        col: "plan",
    }
];